import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterState } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { LoadingService } from 'src/app/services/loader.service';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-quote-detail-header',
  templateUrl: './quote-detail-header.component.html',
  styleUrls: ['./quote-detail-header.component.scss']
})
export class QuoteDetailHeaderComponent implements OnInit {
  displayData = true;
  quoteDetails: any;
  quoteDetailsApi: any;
  quoteTabs: any = [];
  selectedQuoteTab: number = 1;
  cardMetaDataDetails: any;
  @ViewChild('loadContent2', undefined) loadContent;
  createCustomerFormData: any = [];
  customerDataFromApi: any = [];
  accessrialData: any = [];
  serviceTypeData: any = [];
  packageTypeDate: any = [];
  industryType: any;
  loginType = '';
  constructor(
    private router: Router,
    private httpService: ScHttpService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private commonService: ScCommonService,
    private _loaderService: LoadingService
  ) { }

  ngOnInit() {
    this._loaderService.stringSubject.next(true);
    this.loginType = JSON.parse(localStorage.getItem('LOGGEDIN_USER_TYPE'));

    this.quoteTabs = [
      { id: 1, tabName: "Quote Details", show: true },
      { id: 2, tabName: "Shipment Items", show: true },
      { id: 3, tabName: 'Rate', show: true },
      { id: 4, tabName: "Documents", show: true }
    ]
    // let quoteInformation = {
    //   quoteid: '00f2eb11-fd91-4e57-9921-c9d71fac1b2a',
    //   customQuoteId: 'Q-00-08062',
    //   quoteStatus: 'EXECUTED',
    //   customerName: 'Saurav D',
    //   carrierName: 'Shipcarte',
    //   carrierLogo: null,
    //   customerId: 'acc1a451-26c7-4d8d-afdd-ef76fbc8f60d'
    // };
    // sessionStorage.setItem('quoteInformation', JSON.stringify(quoteInformation));
    this.quoteDetails = JSON.parse(sessionStorage.getItem('_adminquoteInformation'));
    this.setQuoteTabId();
    this.getOrderMetaDataById(this.quoteDetails.quoteid);
    this.getQuoteDetailsById(this.quoteDetails.quoteid);
  }
  isQuoteExecuted: Boolean = true;
  openModel() {
    // this.modalService.open(this.loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal', backdrop: 'static' })
  }
  closeModel() {
    this.modalService.dismissAll()
  }

  setQuoteTabId() {
    let urlArray = window.location.href.split('/');
    if (urlArray[urlArray.length - 1] === 'quoteDetails') {
      this.selectedQuoteTab = 1;
    } else if (urlArray[urlArray.length - 1] === 'shipment') {
      this.selectedQuoteTab = 2;
    } else if (urlArray[urlArray.length - 1] === 'rate') {
      this.selectedQuoteTab = 3;
    } else if (urlArray[urlArray.length - 1] === 'document') {
      this.selectedQuoteTab = 4;
    } else {
      this.selectedQuoteTab = 1;
    }
  }
  selectQuoteTab(id) {
    this.selectedQuoteTab = id;
    if (id == 1) {
      this.selectedQuoteTab = 1;
      this.router.navigateByUrl("shipcarte/quoteHistory/quoteDetails")
    } else if (id == 2) {
      this.selectedQuoteTab = 2;
      this.router.navigateByUrl("shipcarte/quoteHistory/quoteDetails/shipment")
    } else if (id == 3) {
      this.selectedQuoteTab = 3;
      this.router.navigateByUrl("shipcarte/quoteHistory/quoteDetails/rate")
    } else if (id == 4) {
      this.selectedQuoteTab = 4;
      this.router.navigateByUrl("shipcarte/quoteHistory/quoteDetails/document")
    }
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('_adminallOrderIdConsolidateQuoteHistory')
  }
  getOrderMetaDataById(id: any) {
    let url = ServerURLS.orderDetails.action + '?quoteId=' + id;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.displayData = false;
      let value = 'EXECUTEDs'
      if ('EXECUTED' == res.quoteStatus) {
        this.isQuoteExecuted = true;
        if (this.loginType != 'admin') {
          this.quoteTabs[2].show = false
        }
      }
      this._loaderService.stringSubject.next(false);
      this.openModel();
      if (res.allOrderIdConsolidate) {
        sessionStorage.setItem('_adminallOrderIdConsolidateQuoteHistory', JSON.stringify(res.allOrderIdConsolidate))
      }
      this.cardMetaDataDetails = res;
      // this.cardMetaDataDetails.createdDate = this.cardMetaDataDetails.createdDate.split(' ');
      // this.cardMetaDataDetails.createdDate[0] = this.cardMetaDataDetails.createdDate[0].split('-');
      // this.cardMetaDataDetails.createdDate[0] = this.cardMetaDataDetails.createdDate[0].join('/');

      // this.originalDeliveryDate = res.originalDeliveryDate;
      sessionStorage.setItem('_adminorderDetailInvoiceSubmitted', this.cardMetaDataDetails.invoiceSubmitted);

      const data = {
        proId: this.cardMetaDataDetails.proId,
        customQuoteId: this.cardMetaDataDetails.customQuoteId,
        carrierId: this.cardMetaDataDetails.carrierId,
        currencyUnit: this.cardMetaDataDetails.currencyUnit,
        estimatedDelveryDate: this.cardMetaDataDetails.deliveryDate,
        trackingId: this.cardMetaDataDetails.orderId
      }

      this.fetchPopupData(this.cardMetaDataDetails.customerId);
      sessionStorage.setItem('_adminorderDetailProbill', JSON.stringify(data));

      //************ Charge */
      let url = ServerURLS.getCustomerOrderPriceComponent.action + '?quoteId=' + id + '&customerId=' + this.cardMetaDataDetails.customerId;
      let promise = this.httpService.getHttpService(url, '');
      promise.then((response) => {
        this.commonService.carrierRates = response;
        sessionStorage.setItem('_admincarrierRatesData', JSON.stringify(response))
        // this.rate = response['totalAmount']
        // this.rateCurrency = response['currency']
      }).catch((res) => {
        this.toastr.error('Some thing wrong', '', { timeOut: 2000 })
      })
      this.closeModel();
    }).catch((err) => {
      console.log(err)
      this._loaderService.stringSubject.next(false);
      this.toastr.error('Some thing wrong', '', { timeOut: 2000 })
      this.modalService.dismissAll()
    })
  }

  getQuoteDetailsById(id) {
    this.openModel();
    let url = ServerURLS.getQuoteDetailsById.action + '?quoteId=' + id;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this._loaderService.stringSubject.next(false);
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.quoteDetailsApi = res;
      this.closeModel();
    }).catch((err) => {
      this._loaderService.stringSubject.next(false);
      this.toastr.error('Some thing wrong', '', { timeOut: 2000 })
      this.closeModel();
    })
  }

  fetchPopupData(customerId) {
    this.openModel();
    let url = ServerURLS.getCreateCustomerForm.action;
    let promise1 = this.httpService.getHttpService(url, '');
    promise1.then((res: any) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.createCustomerFormData = res;
      let URL = ServerURLS.getCustomerById.action + '?customerId=' + customerId;
      let promise2 = this.httpService.getHttpService(URL, '');
      promise2.then((res) => {
        this.customerDataFromApi = res;

        this.createCustomerFormData.business_type.map((element) => {
          if (this.customerDataFromApi.business_type_id == element.business_id) {
            this.industryType = element.business_type;
          }
        })

        this.createCustomerFormData.package_type.map((data) => {
          if (this.customerDataFromApi.package_type && this.customerDataFromApi.package_type !== '' && this.customerDataFromApi.package_type !== null) {
            this.customerDataFromApi.package_type.map((data2) => {
              if (data2.package_id === data.package_id) {
                this.packageTypeDate.push({ "name": data.package_name, "map_id": data2.cust_package_map_id })
              }
            })
          }
        })

        this.createCustomerFormData.services_type.map((data) => {
          if (this.customerDataFromApi.services_type && this.customerDataFromApi.services_type !== '' && this.customerDataFromApi.services_type !== null) {
            this.customerDataFromApi.services_type.map((data2) => {
              if (data2.services_id === data.services_id) {
                this.serviceTypeData.push({ "name": data.service_name, "map_id": data2.cust_services_map_id })
              }
            })
          }
        })

        // accessories
        this.accessrialData = this.customerDataFromApi.accessorials_type;
        this.closeModel();
      }).catch((err) => {
        //  console.log(err)
        this._loaderService.stringSubject.next(false);
        this.toastr.error('Something went wrong')
      })
    })
  }

  delete(type, id) {
    //console.log(type,id)
    this.openModel();
    let url = ServerURLS.deleteCustomerServicePackageAccesorial.action;
    if (type == "ser") {
      url = url + "?featureType=Services&featureMapId=" + id
    } else if (type == "pac") {
      url = url + "?featureType=PackagesType&featureMapId=" + id;
    } else if (type == "acc") {
      url = url + "?featureType=Accessorial&featureMapId=" + id
    }
    // console.log(id)

    let promise = this.httpService.deleteHttpService(url, '');
    promise.then((res: any) => {

      if (type == 'ser') {
        //    this.modalService.open(this.loadContent2,{centered:true,backdropClass:'light-blue-backdrop,',windowClass:'dark-model',backdrop:'static'})

        this.serviceTypeData = this.serviceTypeData.filter((data => data.map_id != id))
      } else if (type == 'pac') {
        this.packageTypeDate = this.packageTypeDate.filter((data => data.map_id != id));
      } else if (type === 'acc') {
        this.accessrialData = this.accessrialData.filter((data => data.cust_accessorial_map_id !== id))
      }
      this.closeModel();
    })
      .catch((err) => {
        this.toastr.error("Something went wrong!")
        this.closeModel();
      })
  }

  routeToEdit(id) {
    sessionStorage.setItem('_admincustomerId', id)
    this.commonService.customerId = id;
    this.router.navigateByUrl('shipcarte/customer/list/generalDetail/edit/shippingReceivingHours')
  }
}
