import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Location } from "@angular/common"
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';
import { LoadingService } from 'src/app/services/loader.service';



@Component({
  selector: 'app-carrier-processing-edit',
  templateUrl: './carrier-processing-edit.component.html',
  styleUrls: ['./carrier-processing-edit.component.scss']
})

export class CarrierProcessingEdit implements OnInit {

  @ViewChild('loadContent', undefined) loadContent;

  carrierProcessingEditId: any;
  cities3: any;
  selectedCityName: any;
  carrierSelectionForm: FormGroup;
  fuelChargeFrom: FormGroup;
  total: number = 0.00;
  selectedEquipment: any = '';
  responseData: any;
  quoteId: any;
  contractRevenue: any = 0;
  insuranceData: any = {};
  registrationData: any = {};
  carrierId: any;
  contractId: any = ''
  carrierName: any = '';
  customerId: any = '';
  currency: any;
  chargeItemList: any = [];
  currencyUnit: any = '';
  paymentDetailsData: any;
  profitPercentage: any = 0;
  profit: any = 0;
  newRowArray: any = [];
  equimentList: any;
  probill: string = '';
  carrierSelected: any = [];
  fuelSurcharges: any = 0;
  getFuleSucharge: any = 0;
  fuelChargeToCalculat: any = 0;
  submitClicked: boolean = false;
  contractCost: any = 0;
  invReceiptGenerated: boolean = false;
  countryData = {
    success: [
      {
        countryPhCode: "+1",
        countryName: "Canada",
        countryCode: "CA",
        provinceState: [
          {
            code: "AB",
            name: "Alberta"
          },
          {
            code: "BC",
            name: "British Columbia"
          },
          {
            code: "MB",
            name: "Manitoba"
          },
          {
            code: "NB",
            name: "New Brunswick"
          },
          {
            code: "NL",
            name: "Newfoundland and Labrador"
          },
          {
            code: "NT",
            name: "Northwest Territories"
          },
          {
            code: "NS",
            name: "Nova Scotia"
          },
          {
            code: "NU",
            name: "Nunavut"
          },
          {
            code: "ON",
            name: "Ontario"
          },
          {
            code: "PE",
            name: "Prince Edward Island"
          },
          {
            code: "QC",
            name: "Quebec"
          },
          {
            code: "SK",
            name: "Saskatchewan"
          },
          {
            code: "YT",
            name: "Yukon"
          }
        ]
      },
      {
        countryPhCode: "+1",
        countryName: "United States",
        countryCode: "US",
        provinceState: [
          {
            code: "AL",
            name: "Alabama"
          },
          {
            code: "AK",
            name: "Alaska"
          },
          {
            code: "AS",
            name: "American Samoa"
          },
          {
            code: "AZ",
            name: "Arizona"
          },
          {
            code: "AR",
            name: "Arkansas"
          },
          {
            code: "CA",
            name: "California"
          },
          {
            code: "CO",
            name: "Colorado"
          },
          {
            code: "CT",
            name: "Connecticut"
          },
          {
            code: "DE",
            name: "Delaware"
          },
          {
            code: "DC",
            name: "District of Columbia"
          },
          {
            code: "FL",
            name: "Florida"
          },
          {
            code: "GA",
            name: "Georgia"
          },
          {
            code: "GU",
            name: "Guam"
          },
          {
            code: "HI",
            name: "Hawaii"
          },
          {
            code: "ID",
            name: "Idaho"
          },
          {
            code: "IL",
            name: "Illinois"
          },
          {
            code: "IN",
            name: "Indiana"
          },
          {
            code: "IA",
            name: "Iowa"
          },
          {
            code: "KS",
            name: "Kansas"
          },
          {
            code: "KY",
            name: "Kentucky"
          },
          {
            code: "LA",
            name: "Louisiana"
          },
          {
            code: "ME",
            name: "Maine"
          },
          {
            code: "MD",
            name: "Maryland"
          },
          {
            code: "MA",
            name: "Massachusetts"
          },
          {
            code: "MI",
            name: "Michigan"
          },
          {
            code: "MN",
            name: "Minnesota"
          },
          {
            code: "MS",
            name: "Mississippi"
          },
          {
            code: "MO",
            name: "Missouri"
          },
          {
            code: "MT",
            name: "Montana"
          },
          {
            code: "NE",
            name: "Nebraska"
          },
          {
            code: "NV",
            name: "Nevada"
          },
          {
            code: "NH",
            name: "New Hampshire"
          },
          {
            code: "NJ",
            name: "New Jersey"
          },
          {
            code: "NM",
            name: "New Mexico"
          },
          {
            code: "NY",
            name: "New York"
          },
          {
            code: "NC",
            name: "North Carolina"
          },
          {
            code: "ND",
            name: "North Dakota"
          },
          {
            code: "OH",
            name: "Ohio"
          },
          {
            code: "OK",
            name: "Oklahoma"
          },
          {
            code: "OR",
            name: "Oregon"
          },
          {
            code: "PA",
            name: "Pennsylvania"
          },
          {
            code: "PR",
            name: "Puerto Rico"
          },
          {
            code: "RI",
            name: "Rhode Island"
          },
          {
            code: "SC",
            name: "South Carolina"
          },
          {
            code: "SD",
            name: "South Dakota"
          },
          {
            code: "TN",
            name: "Tennessee"
          },
          {
            code: "TX",
            name: "Texas"
          },
          {
            code: "VI",
            name: "U.S. Virgin Islands"
          },
          {
            code: "UT",
            name: "Utah"
          },
          {
            code: "VT",
            name: "Vermont"
          },
          {
            code: "VA",
            name: "Virginia"
          },
          {
            code: "WA",
            name: "Washington"
          },
          {
            code: "WV",
            name: "West Virginia"
          },
          {
            code: "WI",
            name: "Wisconsin"
          },
          {
            code: "WY",
            name: "Wyoming"
          }
        ]
      },
    ]
  };
  isFromInvoiceRecieptToEdit:boolean=false;


  ngOnInit(): void {
    let carrierData = sessionStorage.getItem('_admincarrier');
    this.activatedRoute.queryParams.subscribe(params => {
        console.log(params)
        if(params['isReceiptEdit'] && params['isReceiptEdit']=='true'){
          this.isFromInvoiceRecieptToEdit=true;
        }
    });
    if (carrierData) {
      console.log(carrierData,"carreriData")
      this.carrierProcessingEditId = JSON.parse(carrierData).contractId;
      this.quoteId = JSON.parse(carrierData).quoteId;
      this.carrierId = JSON.parse(carrierData).carrierId;
      this.probill = JSON.parse(carrierData).probill;
      this.carrierName = JSON.parse(carrierData).carrierName;
      this.contractId = JSON.parse(carrierData).contractId;
      this.customerId = JSON.parse(carrierData).customerId;
      this.currencyUnit = JSON.parse(carrierData).currencyUnit;

      this.selectedCityName = this.carrierName;

      this.carrierSelectionForm = this.fb.group({
        carrierSelect: [{ value: '', disabled: true }],
        arrangedWith: [''],
        trailerNumber: [''],
        equipment: [''],
        driverName: [""],
        contractRate: [{ value: '', disabled: true }],
        contractRateFlag: [""],
        truckNumber: [''],
        carrierRef: [""],
        instruction: [""],
        paymentDetails: this.fb.array([

        ]),
        baseChanges: [{ value: '', disabled: true }],
        fscAmount: [{ value: '', disabled: true }, [Validators.pattern(/^[0-9]+$/)]],
        subtotal: [{ value: '', disabled: true }],
        balancedue: [{ value: '', disabled: true }]
      })
      this.fuelChargeFrom = this.fb.group({
        fuelAmount: ['', [Validators.required, Validators.pattern(/^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/)]],
        fuelType: ['', [Validators.required,]]
      })

      this.getPaymentDetailData();
    } else {
      this.route.navigateByUrl("shipcarte/allinvoices/contractprocessing")
    }
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('_admincarrier')
  }
  constructor(
    private route: Router,
    private fb: FormBuilder,
    private httpService: ScHttpService,
    private location: Location,
    private modelService: NgbModal,
    private toastr: ToastrService,
    private listDataService:ListDataServiceService,
    private activatedRoute:ActivatedRoute,
    private  _loaderService: LoadingService
  ) {

  }
  carrierLogo:any=''
  carrierTermsData: any;
  fuelAmount: any = 0;
  getPaymentDetailData() {
    this._loaderService.stringSubject.next(true);
    // this.openModel();
   
    let url1 = ServerURLS.getCarrierContractInfo.action + "?quoteId=" + this.quoteId;
    // let url1 = ServerURLS.getBillingInfoCarrierInvoice.action + "?quoteId=" + this.quoteId + "&customerId=" + this.customerId;

    let url2 = ServerURLS.getPartnerInsurance.action + '/' + this.carrierId;
    let url3 = ServerURLS.getRegistrationData.action + this.carrierId;
    let url4 = ServerURLS.getChargeItemListInInvoice.action;
    let url5 = ServerURLS.getEquimentListInInvoice.action;
    let url6 = ServerURLS.getCarrierContractDetailsInInvoice.action + "?quoteId=" + this.quoteId + "&carrierId=" + this.carrierId + "&currency=" + this.currencyUnit;
    let promise4 = this.httpService.getHttpService(url4, '')

    let promise1 = this.httpService.getHttpService(url1, '');
    let promise2 = this.httpService.getHttpService(url2, '');
    let promise3 = this.httpService.getHttpService(url3, '');
    let promise5 = this.httpService.getHttpService(url5, '');
    let promise6 = this.httpService.getHttpService(url6, '');

    promise4.then((res: any) => {
      this.chargeItemList = res;


    })
      .catch((err) => {
      })
    promise1.then((res: any) => {
    this._loaderService.stringSubject.next(false);
      this.paymentDetailsData = res;
      this.invReceiptGenerated = res.invReceiptGenerated ? res.invReceiptGenerated : false;
      if (this.paymentDetailsData) {
        <FormArray>this.carrierSelectionForm.get('paymentDetails')['controls'].splice(0, <FormArray>this.carrierSelectionForm.get('paymentDetails')['controls'].length)
        if (this.paymentDetailsData['charges'].length > 0) {

          <FormArray>this.carrierSelectionForm.get('paymentDetails')['controls'].splice(0, <FormArray>this.carrierSelectionForm.get('paymentDetails')['controls'].length)
          for (let i = 0; i < this.paymentDetailsData['charges'].length; i++) {
            let chargeDesc = '';
            if (this.paymentDetailsData['charges'][i].chargeName == 'Fuel Surcharge') {
              this.fuelAmount = this.paymentDetailsData['charges'][i].totalCharge
            }
            for (let j = 0; j < this.chargeItemList.length; j++) {
              if (this.chargeItemList[j].chargeName === this.paymentDetailsData['charges'][i].chargeName) {
                chargeDesc = this.chargeItemList[j].chargeDesc;
              }
            }
            if (!this.paymentDetailsData['charges'][i].chargeName.includes("HST")) {

              (<FormArray>this.carrierSelectionForm.get("paymentDetails")).push(
                this.fb.group({
                  chargeIte: [this.paymentDetailsData['charges'][i].chargeName, [Validators.required]],
                  probill: [{ value: this.probill, disabled: true }],
                  chargeDescription: [{ value: chargeDesc, disabled: true }],
                  quantity: [{value:this.paymentDetailsData['charges'][i].quantity,disabled:true}, [Validators.required, Validators.min(1), Validators.max(99999), Validators.pattern(/^[0-9]+$/)]],
                  price: [{value:(this.paymentDetailsData['charges'][i].totalCharge) / parseInt(this.paymentDetailsData['charges'][i].quantity),disabled:true}, [Validators.required, Validators.max(99999), Validators.pattern(/^-?\d+(\.\d{1,2})?$/)]],
                  afs: [{value:this.paymentDetailsData['charges'][i].isAfs == "true" ? true : false,disabled:true}],
                  typeOfafs: [this.paymentDetailsData['charges'][i].isFscRatePecent === 'true' ? 'percentage' : this.paymentDetailsData['charges'][i].fscRate > 0 ? 'fixed' : ''],
                  afsAmount: [this.paymentDetailsData['charges'][i].fscRate ? this.paymentDetailsData['charges'][i].fscRate : ''],
                  totalCharge: [{ value: this.paymentDetailsData['charges'][i].totalCharge, disabled: true }],
                  newLine: false,
                  isAdjust:this.paymentDetailsData['charges'][i].isAdjust
                })
              )
            }
          }
        } else {
          (<FormArray>this.carrierSelectionForm.get('paymentDetails')).push(this.addLine())
        }
      } else {
        (<FormArray>this.carrierSelectionForm.get('paymentDetails')).push(this.addLine())
      }

      if (this.paymentDetailsData) {
        this.currency = this.paymentDetailsData['currency'] ? this.paymentDetailsData['currency'] : "";
        if (this.currency === "CAD") {
          this.currency = "CA"
        } else if (this.currency === "USD") {
          this.currency = 'US'
        }
        this.getFuleSucharge = this.paymentDetailsData.fscAmount
        this.fuelChargeToCalculat = this.paymentDetailsData.fscAmount;
        this.contractRevenue = this.paymentDetailsData.contractRev ? this.paymentDetailsData.contractRev : 0;
        this.carrierSelectionForm.patchValue({

          fscAmount: this.paymentDetailsData.fscAmount ? parseFloat(this.paymentDetailsData.fscAmount) + parseFloat(this.fuelAmount) : 0 + parseFloat(this.fuelAmount),
          subtotal: this.paymentDetailsData.totalAmount ? this.paymentDetailsData.totalAmount : '',

        })
      }
      let url7 = ServerURLS.getPartnerAccount.action + '/' + this.carrierId;
      let promise7 = this.httpService.getHttpService(url7, '');
      promise7.then((res: any) => {
        this.carrierTermsData = res;
        if (this.currency === 'CA') {
          this.carrierSelectionForm.patchValue({
            contractRate: this.carrierTermsData.canadaDefaultTerm ? this.carrierTermsData.canadaDefaultTerm.split(' ')[0] : 0
          })
        } else if (this.currency === 'US') {
          this.carrierSelectionForm.patchValue({
            contractRate: this.carrierTermsData.usDefaultTerm ? this.carrierTermsData.usDefaultTerm.split(' ')[0] : 0
          })
        }
      })
      let url = ServerURLS.getPartnerGeneralDetails.action + this.carrierId;
      let promise = this.httpService.getHttpService(url, '');
      promise.then((res:any)=>{ 
        this.carrierLogo=res.general_details.image_download_url
        this.carrierSelected = [
          { id: 1, name: this.carrierName, avatar: res.general_details.image_download_url ? res.general_details.image_download_url: '/assets/img/avatar.png' }
        ]
      })
      this.selectedCityName = this.carrierName;
      this.addTotalCharge();
    })
      .catch((err: any) => {
        this._loaderService.stringSubject.next(false);
        this.paymentDetailsData = {}
        this.toastr.error("Something went wrong!")
      })
    promise2.then((res: any) => {
      this._loaderService.stringSubject.next(false);
      this.insuranceData = res;
    })
      .catch((err: any) => {
      this._loaderService.stringSubject.next(false);
      })
    promise3.then((res: any) => {
      this.registrationData = res;
      this._loaderService.stringSubject.next(false);
    })
      .catch((err: any) => {
      this._loaderService.stringSubject.next(false);
      })

    promise5.then((res: any) => {
      this.equimentList = res
    })
      .catch((err) => {
      })
    promise6.then((res: any) => {
      this.responseData = res;
      if (this.responseData !== '') {
        let equipmentName = this.responseData.equipmentClass ? this.responseData.equipmentClass : '';
        let equipmentId = '';
        this.equimentList.forEach((element, index) => {
          if (element.equipmentClass === equipmentName) {
            equipmentId = element.equipmentId;
          }
        })

        this.carrierSelectionForm.patchValue({
          arrangedWith: this.responseData.arrangedWith ? this.responseData.arrangedWith : '',
          trailerNumber: this.responseData.trailerNumber ? this.responseData.trailerNumber : '',
          driverName: this.responseData.driverName ? this.responseData.driverName : '',
          // contractRate: this.responseData.contractRate ? this.responseData.contractRate : '',
          truckNumber: this.responseData.truckNumber ? this.responseData.truckNumber : '',
          carrierRef: this.responseData.carrierReference ? this.responseData.carrierReference : '',
          instruction: this.responseData.instruction ? this.responseData.instruction : '',
          equipment: equipmentId ? equipmentId : ''
        })
      }
      this.closeModel()

    })
      .catch((err) => {
        this.closeModel()

      })



  }
  updateChargeList() {
    this.carrierSelectionForm.get("paymentDetails")['controls'].forEach((element, index) => {
      let chargeName = element.controls.chargeIte.value;
      if (chargeName) {
        this.chargeItemList.forEach((element, index) => {
          if (element.chargeName === chargeName) {
            this.chargeItemList.splice(index, 1)
          }
        })
      }
    })
  }
  setTerm(value) {

    let currencyCode = value.countryCode;
    let terms = '';
    if (currencyCode === "CA") {
      this.carrierSelectionForm.patchValue({
        contractRate: this.carrierTermsData.canadaDefaultTerm ? this.carrierTermsData.canadaDefaultTerm.split(' ')[0] : 0
      })
    } else if (currencyCode === 'US') {
      this.carrierSelectionForm.patchValue({
        contractRate: this.carrierTermsData.usDefaultTerm ? this.carrierTermsData.usDefaultTerm.split(' ')[0] : 0
      })
    }
  }
  saveData() {
    let currency = this.carrierSelectionForm.get("contractRateFlag").value ? this.carrierSelectionForm.get("contractRateFlag").value : "";
    if (currency !== '') {
      if (currency === 'CA') {
        currency = 'CAD'
      } else if (currency === 'US') {
        currency = "USD"
      }
    } else {
      currency = ''
    }
    // this.openModel();
    let carrierDetailsObject = {

      "quoteId": this.quoteId,

      "arrangedWith": this.carrierSelectionForm.get("arrangedWith").value ? this.carrierSelectionForm.get('arrangedWith').value : '',

      "driverName": this.carrierSelectionForm.get("driverName").value ? this.carrierSelectionForm.get('driverName').value : '',

      "truckNumber": this.carrierSelectionForm.get("truckNumber").value ? this.carrierSelectionForm.get('truckNumber').value : '',

      "trailerNumber": this.carrierSelectionForm.get("trailerNumber").value ? this.carrierSelectionForm.get('trailerNumber').value : '',

      "carrierReference": this.carrierSelectionForm.get("carrierRef").value ? this.carrierSelectionForm.get('carrierRef').value : '',

      "equipmentClass": this.carrierSelectionForm.get("equipment").value ? this.carrierSelectionForm.get('equipment').value : '',

      "contractRate": this.carrierSelectionForm.get("contractRate").value ? parseInt(this.carrierSelectionForm.get('contractRate').value) : '',

      "currencyCode": currency ? currency : '',

      "contractInstruction": this.carrierSelectionForm.get("instruction").value ? this.carrierSelectionForm.get('instruction').value : ''

    }

    let dataArray = [];
    this.carrierSelectionForm.get("paymentDetails")['controls'].forEach(element => {

      let chargenaam = element.controls.chargeIte.value;
      let chargeCode = '';
      if (element.controls.newLine.value) {
        this.chargeItemList.forEach((element, index) => {
          if (element.chargeName === chargenaam) {
            chargeCode = element.chargeId;
          }
        });

      } else {
        chargeCode = '';
        const elementData = this.paymentDetailsData.charges.filter((data => data.chargeName == chargenaam));
        if (elementData[0].chargeCode != '') {
          chargeCode = elementData[0].chargeCode
        } else {
          chargeCode = ''
        }
      }
      if (element.controls.quantity.value > 0  && element.controls.chargeIte.value !== '') {

        let obj = {
          'chargeName': element.controls.chargeIte.value ? element.controls.chargeIte.value.split('&')[0] : '',
          'chargeCode': chargeCode ? chargeCode : '',
          'quantity': element.controls.quantity.value ? element.controls.quantity.value : 0,
          "isAdjust": element.controls.newLine.value == true ? true : false,
          'isAfs': element.controls.afs.value ? element.controls.afs.value : false,
          'totalCharge': element.controls.totalCharge.value ? element.controls.totalCharge.value : '',
          "fscRate": element.controls.afsAmount.value ? element.controls.afsAmount.value : 0,
          "isFscRatePecent": element.controls.typeOfafs.value === 'percentage' ? true : false
        }
        dataArray.push(obj);
      }

    });
    let ChargeName = [];
    dataArray.forEach((element, index) => {
      ChargeName.push(element.chargeName)
    })
    const counts = {};
    let multipleDataFound = false;
    ChargeName.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
    let countArray = Object.values(counts);
    countArray.forEach((element: any, index) => {
      if (element > 1) {
        multipleDataFound = true;
      }
    })


    let paymentDetailsSaveobj = {
      'quoteId': this.quoteId,
      'rates': dataArray,
      'fscAmount': this.carrierSelectionForm.get('fscAmount').value ? (this.carrierSelectionForm.get('fscAmount').value - this.fuelAmount) : '',
    }




    if (this.carrierSelectionForm.valid) {
      if (!multipleDataFound) {

        this._loaderService.stringSubject.next(true);

        let url1 = ServerURLS.saveCarrierContractInfoInInvoice.action;
        let url2 = ServerURLS.saveCarrierContractPaymentDetails.action;

        let promise1 = this.httpService.postHttpService(url1, carrierDetailsObject);
        promise1.then((res: any) => {
          this._loaderService.stringSubject.next(false);
          let promise2 = this.httpService.postHttpService(url2, paymentDetailsSaveobj);
        promise2.then((res: any) => {
          this._loaderService.stringSubject.next(false);

          this.toastr.success("Payment Details Updated", '', { timeOut: 1000 })
          this.listDataService.removeAllDataOfToContractProcessingList()
          if(!this.isFromInvoiceRecieptToEdit){
            this.getPaymentDetailData();
            this.closeModel()
          }else{
            

            const selectedItemsForInvoice=JSON.parse(localStorage.getItem('selectedItemsForInvoice'));
            selectedItemsForInvoice.forEach(element => {
              if (element.quoteIds==this.quoteId) {
                
                element.totalCost=this.contractCost.toFixed(2)
                element.amountDue=this.carrierSelectionForm.get("balancedue").value
              }
            });
            localStorage.setItem('selectedItemsForInvoice', JSON.stringify(selectedItemsForInvoice))
            history.back()
          }

        })
          .catch((err: any) => {
          this._loaderService.stringSubject.next(false);
            this.toastr.error('Unable to save payment Detail, Please try after some time', '', { timeOut: 2000 })
            this.getPaymentDetailData()
            
            this.closeModel()
          })
        })
          .catch((err: any) => {
            this._loaderService.stringSubject.next(false);
            this.toastr.error("Unable to Update contract Detail, Please Try after some time!", '', { timeOut: 2000 })

          })
        
      } else {
        this._loaderService.stringSubject.next(false);
        this.closeModel()
        this.toastr.error("Charge Item is already listed, Please update the value")
      }
    } else {
      this._loaderService.stringSubject.next(false);
      this.carrierSelectionForm.markAllAsTouched()
      this.closeModel()
      this.toastr.error("Please fill the required fields")
    }



  }
  addLine(): FormGroup {
    return this.fb.group({
      chargeIte: ['', [Validators.required]],
      probill: [{ value: this.probill, disabled: true }],
      chargeDescription: [{ value: '', disabled: true }],
      quantity: ['', [Validators.required, Validators.min(1), Validators.max(99999), Validators.pattern(/^[0-9]+$/)]],
      price: ['', [Validators.required, Validators.max(99999), Validators.pattern(/^-?\d+(\.\d{1,2})?$/)]],
      afs: [false],
      typeOfafs: [''],
      afsAmount: [''],
      totalCharge: [{ value: 0, disabled: true }],
      newLine: true,
      isAdjust:"true"
    })
  }
  addlineItem() {
    (<FormArray>this.carrierSelectionForm.get('paymentDetails')).push(this.addLine());
    this.carrierSelectionForm.get('paymentDetails')['controls'].forEach((element, index) => {
      if (element.controls.newLine.value) {
        this.newRowArray.push(index);
      }
    });
  }
  removeLine(id: any) {
    (<FormArray>this.carrierSelectionForm.get("paymentDetails")).removeAt(id)
    this.addTotalCharge();

  }
  redirectToInvoiceList() {
    history.back()
  }
  setChargeDescription(ind: any, index: any) {
    let chargeDesc = '';
    for (let i = 0; i < this.chargeItemList.length; i++) {

      if (ind === this.chargeItemList[i].chargeName) {
        chargeDesc = this.chargeItemList[i].chargeDesc
      }
    }

    // this.updateChargeList();
    this.carrierSelectionForm.get('paymentDetails')['controls'][index].patchValue({
      chargeDescription: chargeDesc
    })
  }
  increaseQuantity(id: any) {
    let value = this.carrierSelectionForm.controls.paymentDetails['controls'][id].controls.quantity.value;
    if (value === null || value === '') {
      value = 0;

    } else {
      value = parseInt(value) + 1;

    }


    this.carrierSelectionForm.controls.paymentDetails['controls'][id].patchValue({
      quantity: value
    })
    let price = this.carrierSelectionForm.get('paymentDetails')['controls'][id].controls.price.value
    let formatNum = Math.ceil(price * value * 100) / 100;

    this.carrierSelectionForm.get("paymentDetails")['controls'][id].patchValue({
      totalCharge: formatNum
    })
    this.calculateFuelChargeFromModelEnteredValue();
    this.addTotalCharge()


  }
  decreaseQuantity(id: any) {
    let value = this.carrierSelectionForm.controls.paymentDetails['controls'][id].controls.quantity.value;
    if (parseInt(value) > 0) {
      value = parseInt(value) - 1;
    }
    this.carrierSelectionForm.controls.paymentDetails['controls'][id].patchValue({
      quantity: value
    })
    let price = this.carrierSelectionForm.get('paymentDetails')['controls'][id].controls.price.value
    let formatNum = Math.ceil(price * value * 100) / 100;
    this.carrierSelectionForm.get("paymentDetails")['controls'][id].patchValue({
      totalCharge: formatNum
    })
    this.calculateFuelChargeFromModelEnteredValue();

    this.addTotalCharge()

  }

  count = 1;
  sumOfRows = 0;
  fuelSurcharge = 0;

  addTotalCharge() {
    this.sumOfRows = 0;
    let sum = 0;
    this.fuelSurcharges = 0;
    for (let i = 0; i < this.carrierSelectionForm.controls.paymentDetails['controls'].length; i++) {
      let value = this.carrierSelectionForm.controls.paymentDetails['controls'][i].controls.totalCharge.value;
      if (!isNaN(value) && this.carrierSelectionForm.controls.paymentDetails['controls'][i].controls.chargeIte.value != 'Fuel Surcharge') {
        sum = sum + parseFloat(value);
      }
    }


    let fuelChargeFromInput = this.carrierSelectionForm.get('fscAmount').value ? this.carrierSelectionForm.get('fscAmount').value : 0;


    this.total = sum;
    this.contractCost = sum + parseFloat(fuelChargeFromInput);
    this.carrierSelectionForm.patchValue({
      baseChanges: sum ? sum.toFixed(2) : 0,
      // fscAmount:fuel,
      subtotal: (this.total + parseFloat(fuelChargeFromInput)).toFixed(2),
      balancedue: (this.total + parseFloat(fuelChargeFromInput)).toFixed(2)
    })
    this.profit = (this.paymentDetailsData.contractRev ? this.paymentDetailsData.contractRev : 0.00) - this.contractCost;
    if (this.contractCost != 0) {
      this.profitPercentage = (this.profit / this.contractCost) * 100;

    } else if (this.contractCost === 0 && this.contractRevenue === 0) {
      this.profitPercentage = 0;
    } else if (this.contractCost === 0 && this.contractRevenue !== 0) {
      this.profitPercentage = 0
    }



  }
  setFuelCharge(index, template: any) {
    this.isPercentage=false;
    let value = this.carrierSelectionForm.get('paymentDetails')['controls'][index].controls.afs.value;
    console.log(value)
    // if (value) {
      // } else {
        this.carrierSelectionForm.get("paymentDetails")['controls'][index].controls.typeOfafs.value = '';
        this.carrierSelectionForm.get("paymentDetails")['controls'][index].controls.afsAmount.value = '';
        this.fuelChargeFrom.reset()
        this.calculateFuelChargeFromModelEnteredValue();
        this.openFuelModel(index, template);
    // }
  }
  openFuelModel(index, template) {
    this.modelService.open(template, { size: 'lg', centered: true, backdrop: true, windowClass: "addFuelChargeClass" }).result.then((result) => {
      if (this.fuelChargeFrom.invalid) {
        this.fuelChargeFrom.markAllAsTouched();
        this.openFuelModel(index, template);
      } else {
        this.carrierSelectionForm.get('paymentDetails')['controls'][index].controls.typeOfafs.value = this.fuelChargeFrom.get("fuelType").value;
        this.carrierSelectionForm.get("paymentDetails")['controls'][index].controls.afsAmount.value = this.fuelChargeFrom.get('fuelAmount').value;
        this.fuelChargeFrom.reset();
        this.calculateFuelChargeFromModelEnteredValue();
      }
    }, (reason) => {
      this.fuelChargeFrom.reset();
      this.carrierSelectionForm.get('paymentDetails')['controls'][index].patchValue({
        afs: false
      })
    })
  }
  isPercentage: boolean = false;
  setApplyFuelSurchargeCode(value) {
    if (value === 'percentage') {
      this.isPercentage = true;
    } else {
      this.isPercentage = false;
    }
  }
  totalFSCAmount: any = 0;
  calculateFuelChargeFromModelEnteredValue() {
    this.totalFSCAmount = 0;
    this.carrierSelectionForm.get('paymentDetails')['controls'].forEach((element, index) => {

      if (element.controls.totalCharge.value > 0 && element.controls.totalCharge.value !== null && element.controls.totalCharge.value !== undefined) {
        if (element.controls.typeOfafs.value === 'percentage') {
          this.totalFSCAmount = this.totalFSCAmount + ((parseFloat(element.controls.afsAmount.value) * parseFloat(element.controls.totalCharge.value)) / 100);
        } else if (element.controls.typeOfafs.value === 'fixed') {
          this.totalFSCAmount = this.totalFSCAmount + parseFloat(element.controls.afsAmount.value)
        }
      }
    })
    this.carrierSelectionForm.patchValue({
      fscAmount: parseFloat(parseFloat(this.totalFSCAmount).toFixed(2)) + parseFloat(this.fuelAmount)
    })
    this.addTotalCharge()
  }
  setFuelInModelToEditFuelCharge(index, template) {
    this.fuelChargeFrom.patchValue({
      fuelType: this.carrierSelectionForm.get('paymentDetails')['controls'][index].controls.typeOfafs.value,
      fuelAmount: this.carrierSelectionForm.get("paymentDetails")['controls'][index].controls.afsAmount.value
    })
    if(this.carrierSelectionForm.get('paymentDetails')['controls'][index].controls.typeOfafs.value=='percentage'){
      this.isPercentage=true
    }
    this.openModelToEditFuelCharge(index, template);
  }
  openModelToEditFuelCharge(index, template) {
    this.modelService.open(template, { size: 'lg', centered: true, backdrop: true, windowClass: 'addFuelChargeClass' }).result.then((result) => {
      if (this.fuelChargeFrom.invalid) {
        this.fuelChargeFrom.markAllAsTouched();
        this.openModelToEditFuelCharge(index, template);
      } else {
        this.carrierSelectionForm.get("paymentDetails")['controls'][index].controls.typeOfafs.value = this.fuelChargeFrom.get('fuelType').value;
        this.carrierSelectionForm.get('paymentDetails')['controls'][index].controls.afsAmount.value = this.fuelChargeFrom.get('fuelAmount').value;
        this.fuelChargeFrom.reset();
        this.calculateFuelChargeFromModelEnteredValue();
      }
    }, (reason) => {

    })
  }
  calculatePrice(value: any, id: any) {
    let quantity = this.carrierSelectionForm.get("paymentDetails")['controls'][id].controls.quantity.value;
    let formatNum = Math.ceil(quantity * value * 100) / 100;
    this.carrierSelectionForm.get('paymentDetails')['controls'][id].patchValue({
      totalCharge: formatNum
    })
    this.calculateFuelChargeFromModelEnteredValue();

    this.addTotalCharge()

  }
  calculateTotalPrice(value: any, id: any) {
    let price = this.carrierSelectionForm.get('paymentDetails')['controls'][id].controls.price.value
    let formatNum = Math.ceil(price * value * 100) / 100;
    this.carrierSelectionForm.get("paymentDetails")['controls'][id].patchValue({
      totalCharge: formatNum
    })
    this.calculateFuelChargeFromModelEnteredValue();
    this.addTotalCharge()

  }
  // setBaseCharge(totalSum: any) {
  //   this.carrierSelectionForm.patchValue({
  //     baseChanges: totalSum,

  //   })
  // }


  openModel() {
    // this.modelService.open(this.loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal', backdrop: 'static' })
  }
  closeModel() {
    this.modelService.dismissAll();
  }
}
