import { Component } from '@angular/core';
import { LoadingService } from '../services/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  constructor(
    private _loader: LoadingService
  ) { }
  showSpinar = false;
  singleTimer: any;
  withOFtd = 40;
  private loaderSubscription!: Subscription;
  ngOnInit() {
    this.loaderSubscription = this._loader.stringSubject.subscribe((isLoading: boolean) => {
      clearInterval(this.singleTimer); // Prevent multiple intervals

      if (isLoading) {
        this.showSpinar = true;
        this.withOFtd = 40;

        this.singleTimer = setInterval(() => {
          if (this.withOFtd >= 100) {
            this.withOFtd = 0;
          }
          this.withOFtd += 4;
        }, 100);
      } else {
        this.showSpinar = false;
        this.withOFtd = 10;
        clearInterval(this.singleTimer);
      }
    });
    setTimeout(() => {
      if (this.showSpinar) {
        this.showSpinar = false;
      }
    }, 400000);
  }

  ngOnDestroy(): void {
    clearInterval(this.singleTimer); // Stop the interval
    // this.loaderSubscription.unsubscribe()// Unsubscribe to prevent memory leaks
  }

}
