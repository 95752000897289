export enum InvoicesTab {
  CustomerInvoice = "customerInvoice",
  PartnerInvoice = "partnerInvoice",
  InvoiceProcessingAudit = "invoiceProcessingAudit",
  InvoiceProcessingToinvoice = "invoiceProcessingAuditToinvoice",
  ContractProcessing = "contractProcessing",
  Insuranceprocessing = "insuranceprocessing",
  SalesRepprocessing = "salesrepprocessing",


}

export const InvoiceTabUrls: { [key in InvoicesTab]: string } = {
  [InvoicesTab.CustomerInvoice]: "allinvoices",
  [InvoicesTab.PartnerInvoice]: "allinvoices/partnerinvoice",
  [InvoicesTab.Insuranceprocessing]: "allinvoices/insuranceprocessing",

  [InvoicesTab.InvoiceProcessingAudit]: "allinvoices/invoice-processing/toAudit",
  [InvoicesTab.InvoiceProcessingToinvoice]: "allinvoices/invoice-processing/toInvoice",
  [InvoicesTab.ContractProcessing]: "allinvoices/contractprocessing",
  [InvoicesTab.SalesRepprocessing]: "allinvoices/salesrepprocessing"

};