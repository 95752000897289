import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { CarrierInvoiceReceiptComponent } from "./carrier-invoice-receipt/carrier-invoice-receipt.component";

import { NgSelectModule } from "@ng-select/ng-select";
import { CarrierProcessingEdit } from "./carrier-processing-edit/carrier-processing-edit.component";
import { AllInvoiceModule } from "./allinvoice/allinvoice.module";
import { AllInvoiceComponent } from "./allinvoice/allinvoice.component";
import { SpinnerModule } from "src/app/spinner/spinner.module";
import { ModuleGuard } from "src/app/services/guard/module.guard";
import { ModuleKeys } from "src/app/models/moduleKeys";
import { InsuranceReceiptComponent } from './insurance-receipt/insurance-receipt.component';
import { SalesinvoicereciptComponent } from './salesinvoicerecipt/salesinvoicerecipt.component';
import { InsuranceProcessingEditComponent } from './insurance-processing-edit/insurance-processing-edit.component';

const routes: Routes = [
  // {
  //     path: '',
  //     component: AllInvoiceComponent,
  //     loadChildren:()=>import('./allinvoice/allinvoice.module').then(m=>m.AllInvoiceModule)
  // },
  {
    path: "shipcarte/allinvoices/carrierinvoicereceipt",
    component: CarrierInvoiceReceiptComponent,
    canActivate: [ModuleGuard],
    data: { permission: ModuleKeys.INVOICES },
  },
  {
    path: "shipcarte/allinvoices/insurancereceipt",
    component: InsuranceReceiptComponent,
    canActivate: [ModuleGuard],
    data: { permission: ModuleKeys.INVOICES },
  },

  {
    path: "shipcarte/allinvoices/salesreceipt",
    component: SalesinvoicereciptComponent,
    canActivate: [ModuleGuard],
    data: { permission: ModuleKeys.INVOICES },
  },

  {
    path: "shipcarte/allinvoices/carrierprocessingEdit",
    component: CarrierProcessingEdit,
    canActivate: [ModuleGuard],
    data: { permission: ModuleKeys.INVOICES },
  },
  {
    path: 'shipcarte/allinvoices/insurance-processing-edit',
    component: InsuranceProcessingEditComponent,
    canActivate: [ModuleGuard],
    data: { permission: ModuleKeys.INVOICES }
  },
  {
    path: "shipcarte/allinvoices",
    component: AllInvoiceComponent,
    loadChildren: () =>
      import("./allinvoice/allinvoice.module").then((m) => m.AllInvoiceModule),
    canActivate: [ModuleGuard],
    data: { permission: ModuleKeys.INVOICES },
  },
];

@NgModule({
  declarations: [
    AllInvoiceComponent,
    CarrierInvoiceReceiptComponent,
    CarrierProcessingEdit,
    InsuranceReceiptComponent,
    SalesinvoicereciptComponent,
    InsuranceProcessingEditComponent,
  ],

  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    SpinnerModule,
  ],
  exports: [
    InsuranceProcessingEditComponent
  ]
})
export class InvoiceModule { }
