import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ScAuthService } from "src/app/services/auth/sc-auth.service";
import { ListDataServiceService } from "src/app/services/list-data-service.service";
import { ScStorageService } from "src/app/services/storage/sc-storage.service";
import {
  EnvironmentConstants,
  StorageKeys,
} from "src/environments/environment";

@Component({
  selector: "app-shipcarte-admin-login",
  templateUrl: "./shipcarte-admin-login.component.html",
  styleUrls: ["./shipcarte-admin-login.component.scss"],
})
export class ShipcarteAdminLoginComponent implements OnInit {
  loginForm: FormGroup;
  currentYear = new Date().getFullYear();
  submitClicked = false;
  postLoginURL: string = "";
  procesing_img = EnvironmentConstants.IMAGE_LOADER;
  isApiProcessing = false;
  selectedLanguage;
  newPasswordVisible = true;
  languages = [
    { id: 'en', name: 'English', flagSrc: 'assets/img/common/US-2.svg' },
  ];
  redirectUrls = {
    admin: "shipcarte/dashboard",
    salesRep: "shipcarte/dashboard",
    operation: "shipcarte/orders",
    finance: "shipcarte/allinvoices",
  };

  validationMessages = {
    email: {
      required: "Username is required*",
    },
    password: {
      required: "Password is required*",
    },
  };

  formErrors = {
    email: "",
    password: "",
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private storageService: ScStorageService,
    private scAuthService: ScAuthService,
    private modalService: NgbModal,
    private listDataService: ListDataServiceService,
    private toastService: ToastrService
  ) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required]],
      password: ["", Validators.required],
      rememberMe: [false],
    });
  }

  ngOnInit(): void {
    this.selectedLanguage = this.languages[0];
    setTimeout(() => {
      this.selectedLanguage = { ...this.languages[0] };
    }, 0);
    localStorage.removeItem("setpOneData");
    this.modalService.dismissAll();
    this.listDataService.removeAllListData();
    let isUserAlreadyLogin = this.scAuthService.isLoggedSession();

    if (isUserAlreadyLogin != "") {
      this.naviagteToDefaultRoute();
    }
    this.postLoginURL = "shipcarte/dashboard";
  }
  togglePassword() {
    this.newPasswordVisible = !this.newPasswordVisible;
  }
  naviagteToDefaultRoute() {
    const role = this.storageService.getBucket(StorageKeys.LOGGEDIN_USER_TYPE);
    const redirectUrl = this.redirectUrls[role] || "shipcarte/dashboard";
    this.router.navigateByUrl(redirectUrl);
  }

  onChange(language): void {
    console.log('Language changed:', language);
    // Implement your language change logic here
  }

  loginUser() {
    try {
      this.isApiProcessing = true;
      let formData = this.loginForm.getRawValue();
      let email = formData.email;
      let password = formData.password;

      let actorType = "";
      actorType = "admin";
      let authPromise = this.scAuthService.signinUser(
        email,
        password,
        "",
        actorType,
        this.postLoginURL
      );

      authPromise
        .then((response) => {
          if (response.data) {
            this.naviagteToDefaultRoute();
          } else {
            throw new Error("No user object found");
          }
          this.isApiProcessing = false;
        })
        .catch((error) => {
          let errorMessage = "";
          this.isApiProcessing = false;
          if (error.error) {
            errorMessage = error.error.includes("Invalid email or password.")
              ? "Invalid username or password."
              : error.error;
          } else {
            errorMessage = error;
          }
          this.toastService.error(errorMessage);
        });
    } catch (err) {
    }
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      this.loginUser();
    } else {
      this.submitClicked = true;
      this.logValidationErrors();
    }
  }

  logValidationErrors(group: FormGroup = this.loginForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      this.formErrors[key] = "";
      if (
        (abstractControl &&
          !abstractControl.valid &&
          (abstractControl.touched || abstractControl.dirty)) ||
        this.submitClicked
      ) {
        const messages = this.validationMessages[key];
        for (const errorKey in abstractControl.errors) {
          if (errorKey) {
            this.formErrors[key] += messages[errorKey] + " ";
          }
        }
      }
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      }
    });
  }

  forgotPassword(): void {
    // TODO Implement forgot password logic
  }
}
