import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './sign-in/sign-in.component';
import { Routes, RouterModule } from '@angular/router';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AuthFormComponent } from './auth-form/auth-form.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { ScSocialAuthServiceService } from '../../services/social/sc-social-auth-service.service';
import { SetPasswordComponent } from './set-password/set-password.component';
import { ShipcarteAdminLoginComponent } from './shipcarte-admin-login/shipcarte-admin-login.component';



const routes: Routes = [
  { path: '', redirectTo: 'shipcarte/signin', pathMatch: 'full' },
  { path: 'shipcarte/signin', component: SignInComponent},
  { path: 'shipcarte/signup', component: SignUpComponent},
  { path: 'shipcarte/user/login', component: ShipcarteAdminLoginComponent},
  // { path: 'shipcarte/user/login2', component: ShipcarteAdminLoginComponent},
  { path: 'shipcarte/autz/actor', component: AuthFormComponent},
  { path: 'shipcarte/forgot/password', component: ForgotPasswordComponent},
  { path: 'shipcarte/set/password', component: SetPasswordComponent}

];

@NgModule({
  declarations: [SignInComponent, SignUpComponent, AdminLoginComponent, AuthFormComponent, ForgotPasswordComponent, SetPasswordComponent, ShipcarteAdminLoginComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  providers: [ 
    ScSocialAuthServiceService
  ]  
})
export class AutnModule { }
