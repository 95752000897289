import { Component, OnInit, ViewChild } from '@angular/core';
import { ScAuthService } from 'src/app/services/auth/sc-auth.service';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { Router } from '@angular/router';
import { moveItemInArray, CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop'
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, first } from 'rxjs/operators';
import * as ApexCharts from 'apexcharts';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as XLSX from 'xlsx';
import * as fileSaver from 'file-saver';
import { element } from 'protractor';
import { time } from 'console';
import { ToastrService } from 'ngx-toastr';
import { Server } from 'http';
import { parse } from 'querystring';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';
import { LoadingService } from 'src/app/services/loader.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],

})
export class DashboardComponent implements OnInit {
  @ViewChild('loadContent', undefined) loadConten;
  keyIndicatorFilterValue: any = 'LAST_6_MONTHS'
  displayData = true;
  keyIndicatorTime: any = '6 Month'
  keyIndicatorFilterForm: FormGroup;
  graphFilterForm: FormGroup
  quoteTotalData: any
  executedQuoteTotalData: any;
  activeUserTotal: any
  inActiveUserTotal: any;
  failedQuotes: number = 0;
  topCarrierlist: any = {
    'weight': 0,
    'totalOrder': 0,
    'carrierList': []
  }
  allShipmentData: any = {
    'canada': 0,
    'us': 0,
    'tb': 0
  };
  userShipmentPerMonth: any;
  courierLTLPerMonth: any;
  regionOfShipping = {
    series: [20, 20, 20, 20, 20],
    colors: ['#5371FF', '#F5A623', '#FFD319', '#F52D23', '#ffffff'],
    chart: {

      type: 'donut',
      width: 400,
      borderWith: 40
    },
    dataLabels: {
      enabled: true,

    },
    labels: ['UPS Domestic', '', '', '', ''],
    legend: {
      show: false,
    },
    fill: {
      type: 'fill',
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,

        },
        legend: {
          position: 'bottom',

        }
      }
    }],
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          '<img style="margin: 10px" width="20" height="20" src="https://cdn.pixabay.com/photo/2020/12/29/10/07/coast-5870088_960_720.jpg">' +

          "<span>" +
          w.globals.labels[seriesIndex] +
          ": " +
          series[seriesIndex] +
          "</span>" +
          "</div>"
        );
      }
    },
    plotOptions: {
      pie: {
        expandOnClick: false,

        donut: {

          size: '54%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '22px',
              offsetY: -5
            },
            value: {
              show: true,
              fontSize: '16px',
              color: undefined,
              offsetY: +5,
              formatter: function (val) {
                return val
              }
            },
            total: {
              show: true,
              label: 'Shipments',
              color: '#354052',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0)
              }
            }
          }
        }
      }
    }
  };
  quotation: any;
  allShipment: any;
  topFiveCarrier: any;
  userAccount: any;
  quoteByDevices: any = {
    series: [
      {

        data: [34, 56, 54, 23, 67, 45]
      },
      {


        data: [56, 45, 67, 43, 21, 100]
      }
    ],
    chart: {
      type: "bar",
      height: 260,

    },
    colors: ['rgb(254, 176, 25)', '#5371FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '60%',
        dataLabels: {
          position: "top"
        }
      }
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#46B85B"]
      }
    },
    stroke: {
      show: true,
      width: 4,
      colors: ["#fff"]
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May']
    }
  };
  userActivity = {
    series: [{
      data: [45, 64]
    }],
    chart: {
      height: 260,
      type: 'bar',


    },
    colors: ['#F52D23', '#5371FF', '#F5A623'],
    plotOptions: {
      bar: {
        columnWidth: '10%',
        distributed: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: ['Canada', 'United States', 'Trans-Border'],
      labels: {
        style: {

          fontSize: '12px'
        }
      }
    }
  };
  groups: any = [{
    id: 1,
    title: 'Group 1',
    items: [{
      name: 'Item 1 - Group 1',
      heading: 'Abandon Carts',
      desc: 'How many abandon cart.',
      total: 0,
      hoverAmount: 0,
      percentage: '',
      view: true,
      isPercentage: true,
      isProfit: false
    },
    {
      name: 'Item 1 - Group 1',
      heading: 'Quotes Generated',
      desc: 'How many quotes were created',
      total: 0,
      hoverAmount: 0,
      percentage: 0,
      view: true,
      isPercentage: true,
      isProfit: false
    }]
  },
  {
    id: 2,
    title: 'Group 2',
    items: [{
      name: 'Item 1 - Group 2',
      heading: 'Claims',
      desc: 'How many claims were created',
      total: 0,
      hoverAmount: 0,
      percentage: 0,
      view: true,
      isPercentage: true,
      isProfit: false
    },
    {
      name: 'Item 1 - Group 2',
      heading: 'Shipments',
      desc: 'How many orders were created',
      total: 0,
      hoverAmount: 0,
      percentage: 0,
      view: true,
      isPercentage: true,
      isProfit: false
    }]
  },
  {
    id: 3,
    title: 'Group 3',
    items: [{
      name: 'Item 1 - Group 3',
      heading: 'Invoice Created',
      desc: 'How many invoice were created.',
      total: 0,
      hoverAmount: 0,
      percentage: 0,
      view: true,
      isPercentage: true,
      isProfit: false
    },
    {
      name: 'Item 1 - Group 3',
      heading: 'Sales (USD)',
      desc: 'How many outstanding sales.',
      total: 0,
      hoverAmount: 0,
      percentage: 0,
      view: true,
      isPercentage: true,
      isProfit: false
    }]
  },
  {
    id: 4,
    title: 'Group 4',
    items: [{
      name: 'Item 1 - Group 4',
      heading: 'Sales (CAD)',
      desc: 'How many outstanding sales.',
      img: "assets/img/Supplies.svg",
      total: 0,
      hoverAmount: 0,
      percentage: 0,
      view: true,
      isPercentage: true,
      isProfit: false

    },
    {
      name: 'Item 1 - Group 4',
      heading: 'New Customer Added',
      desc: 'How many Customer Added.',
      img: "assets/img/Supplies.svg",
      total: 0,
      hoverAmount: 0,
      percentage: 0,
      view: true,
      isPercentage: true,
      isProfit: false

    }]
  }
  ];
  staticGroups: any = [{
    id: 1,
    title: 'Group 1',
    items: [{
      name: 'Item 1 - Group 1',
      heading: 'Abandon Carts',
      desc: 'How many abandon cart.',
      total: 0,
      percentage: 0,
      view: true,
      isPercentage: true,
      isProfit: false
    },
    {
      name: 'Item 1 - Group 1',
      heading: 'Quotes Generated',
      desc: 'How many quotes were created',
      total: 0,
      percentage: 0,
      view: true,
      isPercentage: true,
      isProfit: false
    }]
  },
  {
    id: 2,
    title: 'Group 2',
    items: [{
      name: 'Item 1 - Group 2',
      heading: 'Claims',
      desc: 'How many claims were created',
      total: 0,
      percentage: 0,
      view: true,
      isPercentage: true,
      isProfit: false
    },
    {
      name: 'Item 1 - Group 2',
      heading: 'Shipments',
      desc: 'How many orders were created',
      total: 0,
      percentage: 0,
      view: true,
      isPercentage: true,
      isProfit: false
    }]
  },
  {
    id: 3,
    title: 'Group 3',
    items: [{
      name: 'Item 1 - Group 3',
      heading: 'Invoice Created',
      desc: 'How many invoice were created.',
      total: 0,
      percentage: 0,
      view: true,
      isPercentage: true,
      isProfit: false
    },
    {
      name: 'Item 1 - Group 3',
      heading: 'Sales (USD)',
      desc: 'How many outstanding sales.',
      total: 0,
      percentage: 0,
      view: true,
      isPercentage: true,
      isProfit: false
    }]
  },
  {
    id: 4,
    title: 'Group 4',
    items: [{
      name: 'Item 1 - Group 4',
      heading: 'Sales (CAD)',
      desc: 'How many outstanding sales.',
      img: "assets/img/Supplies.svg",
      total: 0,
      percentage: 0,
      view: true,
      isPercentage: true,
      isProfit: false

    },
    {
      name: 'Item 1 - Group 4',
      heading: 'New Customer Added',
      desc: 'How many Customer Added.',
      img: "assets/img/Supplies.svg",
      total: 0,
      percentage: 0,
      view: true,
      isPercentage: true,
      isProfit: false

    }]
  }
  ]
  leftRow = [
    {
      'heading': 'User Shipment Per Month',
      "desc": "How many shipments per month.",
      "show": true
    },
    {
      'heading': "Quotes Per Month",
      'desc': 'How many Quotes are searched and executed per month.',
      "show": true
    },
    {
      'heading': 'Top 5 Carriers For User',
      'desc': 'List of your top carriers',
      'show': true
    },


    // {
    //   'heading': 'Quotes By  Device',
    //   'desc': 'How many quotes were executed through mobile/desktop',
    //   'show': true
    // }
  ];
  staticLeftRow = [
    {
      'heading': 'User Shipment Per Month',
      "desc": "How many shipments per month.",
      "show": true
    },
    {
      'heading': "Quotes Per Month",
      'desc': 'How many Quotes are searched and executed per month.',
      "show": true
    },
    {
      'heading': 'Top 5 Carriers For User',
      'desc': 'List of your top carriers',
      'show': true
    },


    // {
    //   'heading': 'Quotes By  Device',
    //   'desc': 'How many quotes were executed through mobile/desktop',
    //   'show': true
    // }
  ];
  rightRow = [
    // {
    //   'heading': 'Regions Of Shipping',
    //   "desc": "Regional shipping of all users",
    //   "show": true
    // },
    // {
    //   'heading': "All Shipments",
    //   'desc': 'All shipment by countries and trans-border.',
    //   "show": true
    // },

    {
      'heading': 'Shipment Deliver by Region',
      'desc': 'List of Shipment Deliver by Region',
      'show': true
    },
    {
      'heading': 'User Accounts',
      'desc': 'New user accounts per month',
      'show': true
    },

    {
      'heading': 'Courier / LTL executed Per Month',
      "desc": "How many Courier / LTL executed per month.",
      "show": true
    },



    // ,
    // {
    //   'heading': 'User Activity',
    //   'desc': 'Log in activity by users',
    //   'show': true
    // }
  ];
  staticRightRow = [
    // {
    //   'heading': 'Regions Of Shipping',
    //   "desc": "Regional shipping of all users",
    //   "show": true
    // },
    // {
    //   'heading': "All Shipments",
    //   'desc': 'All shipment by countries and trans-border.',
    //   "show": true
    // },


    {
      'heading': 'Shipment Deliver by Region',
      'desc': 'List of Shipment Deliver by Region',
      'show': true
    },

    {
      'heading': 'User Accounts',
      'desc': 'New user accounts per month',
      'show': true
    },

    {
      'heading': 'Courier / LTL executed Per Month',
      "desc": "How many Courier / LTL executed per month.",
      "show": true
    },


    // ,
    // {
    //   'heading': 'User Activity',
    //   'desc': 'Log in activity by users',
    //   'show': true
    // }
  ];
  totalLTLCount: any;
  totalCouriers: any;
  fromDate: string;
  toDate: string;
  shipmentByRegionForm: FormGroup;
  shipmentUSARegion: any = [];
  shipmentCARegion: any = [];
  constructor(private scAuthService: ScAuthService, private router: Router, private storageService: ScStorageService, public commonService: ScCommonService, private scHttpService: ScHttpService, private modelService: NgbModal, private formBulder: FormBuilder, private toastr: ToastrService, private listDataService: ListDataServiceService, private _loaderService: LoadingService) { }

  ngOnInit() {
    this.keyIndicatorFilterForm = this.formBulder.group({
      filterName: ['LAST_6_MONTHS']
    })
    this.graphFilterForm = this.formBulder.group({
      filterName: ['LAST_6_MONTHS']
    })
    this.shipmentByRegionForm = this.formBulder.group({
      country: ['canada']
    })
    let isUserLoggedIn = this.scAuthService.isLoggedSession();
    if (isUserLoggedIn === false) {
      this.router.navigateByUrl('shipcarte/user/login')
    }
    this.getKeyIndicatorData('LAST_6_MONTHS');

    let date = this.getFromDateAndToDate(6);
    this.fromDate = date.fromDate;
    this.toDate = date.toDate;
    this.getGraphData(date.fromDate, date.toDate)
    this._loaderService.stringSubject.next(true);

  }
  getKeyIndicatorData(time) {
    this.openModel()
    let url = ServerURLS.getAbandanCartDateKeyIndicator.action + '?dateFilter=' + time;
    let promise = this.scHttpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.displayData = false
      this._loaderService.stringSubject.next(false);
      let currentWk = res['details'].current;
      let lastWk = res['details'].last;
      let obj = {
        total: 0,
        percentage: 0,
        isProfit: null
      }
      if (lastWk === 0) {
        let total = 100;
        obj.total = res['details'].current;
        obj.percentage = parseFloat((total).toFixed(2));
        obj.isProfit = true;
      } else {
        if (currentWk > lastWk) {
          let total = ((currentWk - lastWk) * 100) / lastWk;
          obj.total = res['details'].current
          obj.percentage = parseFloat((total).toFixed(2));
          obj.isProfit = true
        }
        else if (currentWk < lastWk) {
          let total = ((lastWk - currentWk) * 100) / lastWk;
          obj.total = res['details'].current;
          obj.percentage = -parseFloat((total).toFixed(2));
          obj.isProfit = false
        }
        else if (currentWk === lastWk) {
          obj.total = res['details'].current;
          obj.percentage = 0;
          obj.isProfit = null
        }
      }
      this.groups.map((element1) => {

        element1.items.map((element2) => {

          if (element2.heading === 'Abandon Carts') {
            let totalAmount = this.formatNumberIntoCADFormat(obj.total)

            element2.total = totalAmount.split('$')[1].split('.')[0]

            // element2.total = this.formatNumber(obj.total)
            element2.hoverAmount = obj.total
            element2.isProfit = obj.isProfit
            element2.percentage = obj.percentage
          }
        })
      })
    })
    let url2 = ServerURLS.getSalesCADDateKeyIndicator.action + '?dateFilter=' + time;
    let promise1 = this.scHttpService.getHttpService(url2, '');
    promise1.then((res: any) => {
      let currentWk = res['details'].current;
      let lastWk = res['details'].last;
      let obj = {
        total: 0,
        percentage: 0,
        isProfit: null
      }
      if (lastWk === 0) {
        let total = 100;
        obj.total = res['details'].current;
        obj.percentage = parseFloat((total).toFixed(2));
        obj.isProfit = true;
      } else {
        if (currentWk > lastWk) {
          let total = ((currentWk - lastWk) * 100) / lastWk;
          obj.total = res['details'].current
          obj.percentage = parseFloat((total).toFixed(2));
          obj.isProfit = true
        } else if (currentWk < lastWk) {
          let total = ((lastWk - currentWk) * 100) / lastWk;
          obj.total = res['details'].current;
          obj.percentage = -parseFloat((total).toFixed(2));
          obj.isProfit = false
        } else if (currentWk === lastWk) {
          obj.total = res['details'].current;
          obj.percentage = 0;
          obj.isProfit = null
        }
      }
      this.groups.map((element1) => {
        element1.items.map((element2) => {
          if (element2.heading === 'Sales (CAD)') {
            let totalAmount = this.formatNumberIntoCADFormat(obj.total)
            if (parseInt(totalAmount.split('.')[1]) > 0) {
              element2.total = '$ ' + totalAmount.split('$')[1] + ' CAD'
            } else {
              element2.total = '$ ' + totalAmount.split('$')[1].split('.')[0] + ' CAD'
            }
            // element2.total = this.formatNumber(obj.total)
            element2.hoverAmount = obj.total
            element2.isProfit = obj.isProfit
            element2.percentage = obj.percentage
            return
          }
        })
      })
    })
    let url3 = ServerURLS.getSalesUSDDateKeyIndicator.action + '?dateFilter=' + time;
    let promise3 = this.scHttpService.getHttpService(url3, '');
    promise3.then((res: any) => {
      let currentWk = res['details'].current;
      let lastWk = res['details'].last;
      let obj = {
        total: 0,
        percentage: 0,
        isProfit: null
      }
      if (lastWk === 0) {
        let total = 100;
        obj.total = res['details'].current;
        obj.percentage = parseFloat((total).toFixed(2));
        obj.isProfit = true;
      } else {
        if (currentWk > lastWk) {
          let total = ((currentWk - lastWk) * 100) / lastWk;
          obj.total = res['details'].current
          obj.percentage = parseFloat((total).toFixed(2));
          obj.isProfit = true
        }
        else if (currentWk < lastWk) {
          let total = ((lastWk - currentWk) * 100) / lastWk;
          obj.total = res['details'].current;
          obj.percentage = -parseFloat((total).toFixed(2));
          obj.isProfit = false
        }
        else if (currentWk === lastWk) {
          obj.total = res['details'].current;
          obj.percentage = 0;
          obj.isProfit = null
        }
      }
      this.groups.map((element1) => {
        element1.items.map((element2) => {
          if (element2.heading === 'Sales (USD)') {
            let totalAmount = this.formatNumberIntoCADFormat(obj.total)
            if (parseInt(totalAmount.split('.')[1]) > 0) {
              element2.total = '$ ' + totalAmount.split('$')[1] + ' USD'
            } else {
              element2.total = '$ ' + totalAmount.split('$')[1].split('.')[0] + ' USD'
            }
            // element2.total = this.formatNumber(obj.total),
            element2.hoverAmount = obj.total
            element2.isProfit = obj.isProfit
            element2.percentage = obj.percentage
            return
          }
        })
      })
    })
    let url4 = ServerURLS.getInvoiceCreatedKeyIndictaor.action + '?dateFilter=' + time;
    let promise4 = this.scHttpService.getHttpService(url4, '');
    promise4.then((res: any) => {
      let currentWk = res['details'].currentCount;
      let lastWk = res['details'].lastCount;
      let obj = {
        total: 0,
        percentage: 0,
        isProfit: null
      }
      if (lastWk === 0) {
        let total = 100;
        obj.total = res['details'].currentCount;
        obj.percentage = parseFloat((total).toFixed(2));
        obj.isProfit = true;
      } else {
        if (currentWk > lastWk) {
          let total = ((currentWk - lastWk) * 100) / lastWk;
          obj.total = res['details'].currentCount
          obj.percentage = parseFloat((total).toFixed(2));
          obj.isProfit = true
        } else if (currentWk < lastWk) {
          let total = ((lastWk - currentWk) * 100) / lastWk;
          obj.total = res['details'].currentCount;
          obj.percentage = -parseFloat((total).toFixed(2));
          obj.isProfit = false
        } else if (currentWk === lastWk) {
          obj.total = res['details'].currentCount;
          obj.percentage = 0;
          obj.isProfit = null
        }
      }
      this.groups.map((element1) => {
        element1.items.map((element2) => {
          if (element2.heading === 'Invoice Created') {

            let totalAmount = this.formatNumberIntoCADFormat(obj.total)


            element2.total = totalAmount.split('$')[1].split('.')[0]
            element2.hoverAmount = obj.total
            element2.isProfit = obj.isProfit
            element2.percentage = obj.percentage
            return
          }
        })
      })
    })
    let url5 = ServerURLS.getQuoteGeneratedKeyIndictaor.action + '?dateFilter=' + time;
    let promise5 = this.scHttpService.getHttpService(url5, '');
    promise5.then((res: any) => {
      let currentWk = res['details'].current;
      let lastWk = res['details'].last;
      let obj = {
        total: 0,
        percentage: 0,
        isProfit: null
      }
      if (lastWk === 0) {
        let total = 100;
        obj.total = res['details'].current;
        obj.percentage = parseFloat((total).toFixed(2));
        obj.isProfit = true;
      } else {
        if (currentWk > lastWk) {
          let total = ((currentWk - lastWk) * (lastWk === 0 ? 1 : 100)) / (lastWk === 0 ? 1 : lastWk);
          obj.total = res['details'].current
          obj.percentage = parseFloat((total).toFixed(2));
          obj.isProfit = true
        } else if (currentWk < lastWk) {
          let total = ((lastWk - currentWk) * (lastWk === 0 ? 1 : 100)) / (lastWk === 0 ? 1 : lastWk);
          obj.total = res['details'].current;
          obj.percentage = -parseFloat((total).toFixed(2));
          obj.isProfit = false
        } else if (currentWk === lastWk) {
          obj.total = res['details'].current;
          obj.percentage = 0;
          obj.isProfit = null
        }
      }
      this.groups.map((element1) => {
        element1.items.map((element2) => {
          if (element2.heading === 'Quotes Generated') {
            let totalAmount = this.formatNumberIntoCADFormat(obj.total)


            element2.total = totalAmount.split('$')[1].split('.')[0]
            element2.hoverAmount = obj.total
            element2.isProfit = obj.isProfit
            element2.percentage = obj.percentage
            return
          }
        })
      })
    })
    let url6 = ServerURLS.getShipmentsKeyIndictaor.action + '?dateFilter=' + time;
    let promise6 = this.scHttpService.getHttpService(url6, '');
    promise6.then((res: any) => {
      let currentWk = res['details'].current;
      let lastWk = res['details'].last;
      let obj = {
        total: 0,
        percentage: 0,
        isProfit: null
      }
      if (lastWk === 0) {
        let total = 100;
        obj.total = res['details'].current;
        obj.percentage = parseFloat((total).toFixed(2));
        obj.isProfit = true;
      } else {
        if (currentWk > lastWk) {
          let total = ((currentWk - lastWk) * 100) / lastWk;
          obj.total = res['details'].current
          obj.percentage = parseFloat((total).toFixed(2));
          obj.isProfit = true
        } else if (currentWk < lastWk) {
          let total = ((lastWk - currentWk) * 100) / lastWk;
          obj.total = res['details'].current;
          obj.percentage = -parseFloat((total).toFixed(2));
          obj.isProfit = false
        } else if (currentWk === lastWk) {
          obj.total = res['details'].current;
          obj.percentage = 0;
          obj.isProfit = null
        }
      }
      this.groups.map((element1) => {
        element1.items.map((element2) => {
          if (element2.heading === 'Shipments') {
            let totalAmount = this.formatNumberIntoCADFormat(obj.total)


            element2.total = totalAmount.split('$')[1].split('.')[0]
            // element2.total = this.formatNumber(obj.total)
            element2.hoverAmount = obj.total
            element2.isProfit = obj.isProfit
            element2.percentage = obj.percentage
            return
          }
        })
      })
    })
    let url7 = ServerURLS.getNewCustomerAddedKeyIndictaor.action + '?dateFilter=' + time;
    let promise7 = this.scHttpService.getHttpService(url7, '');
    promise7.then((res: any) => {
      let currentWk = res['details'].current;
      let lastWk = res['details'].last;
      let obj = {
        total: 0,
        percentage: 0,
        isProfit: null
      }
      if (lastWk === 0) {
        let total = 100;
        obj.total = res['details'].current;
        obj.percentage = parseFloat((total).toFixed(2));
        obj.isProfit = true;
      } else {
        if (currentWk > lastWk) {
          let total = ((currentWk - lastWk) * (lastWk === 0 ? 1 : 100)) / (lastWk === 0 ? 1 : lastWk);
          obj.total = res['details'].current
          obj.percentage = parseFloat((total).toFixed(2));
          obj.isProfit = true
        }
        else if (currentWk < lastWk) {
          let total = ((lastWk - currentWk) * (lastWk === 0 ? 1 : 100)) / (lastWk === 0 ? 1 : lastWk);
          obj.total = res['details'].current;
          obj.percentage = -parseFloat((total).toFixed(2));
          obj.isProfit = false
        }
        else if (currentWk === lastWk) {
          obj.total = res['details'].current;
          obj.percentage = 0;
          obj.isProfit = null
        }
      }
      this.groups.map((element1) => {
        element1.items.map((element2) => {
          if (element2.heading === 'New Customer Added') {
            let totalAmount = this.formatNumberIntoCADFormat(obj.total)


            element2.total = totalAmount.split('$')[1].split('.')[0]
            element2.hoverAmount = obj.total
            element2.isProfit = obj.isProfit
            element2.percentage = obj.percentage
            return
          }
        })
      })
      this.closeModel()
      this.changeTheBottomHeadingInKeyIndicator(time);
    })
  }
  formatNumberIntoCADFormat(insuranceAmount) {

    const formattedCurrency = new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
    }).format(insuranceAmount);
    return formattedCurrency
  }
  changeTheBottomHeadingInKeyIndicator(value) {
    if (value === 'LAST_6_MONTHS') {
      this.keyIndicatorTime = '6 Months'
    } else if (value === 'LAST_3_MONTHS') {
      this.keyIndicatorTime = '3 Months'
    } else if (value === 'LAST_9_MONTHS') {
      this.keyIndicatorTime = '9 Months'
    } else if (value === 'LAST_YEAR') {
      this.keyIndicatorTime = '1 Year'
    }
  }

  carrierIdData = {};
  getGraphData(fromDate, toDate) {
    this.openModel()
    this._loaderService.stringSubject.next(true)
    let url4 = ServerURLS.getGraphDataOfTopCarrierList.action + '?fromDate=' + fromDate + '&toDate=' + toDate;
    let promise4 = this.scHttpService.getHttpService(url4, '');
    promise4.then((res: any) => {
      this.displayData = false
      this._loaderService.stringSubject.next(false);
      let seriesArray = [];
      this.topCarrierlist = {
        'weight': 0,
        'totalOrder': 0,
        'carrierList': []
      }
      this.topCarrierlist.weight = res['averageWeight'];
      this.topCarrierlist.totalOrder = res['totalOrders'];
      let colorsArray = ['#F52D23', '#F5A623', '#5371FF', '#46B85B', '#23A9F5', '#5eb8ff']
      let carrierNameArray = [];
      res['topCarriersList'].map((element, index) => {
        let data;
        let carrierName;
        data = Object.values(element)[0];
        carrierName = Object.keys(element);

        this.topCarrierlist.carrierList.push({
          'color': colorsArray[index],
          'carrierName': carrierName[0],
          'value': data.split('%')[0]
        })
        carrierNameArray.push(carrierName[0])
        this.carrierIdData[carrierName[0]] = element.carrierId
        seriesArray.push(parseFloat(data.split('%')[0]))
      })
      let sum = 0;
      seriesArray.map((element) => {
        sum = sum + element
      })

      seriesArray.push(parseFloat((100 - sum).toFixed(2)));
      carrierNameArray.push("Others");
      this.topFiveCarrier = {
        series: seriesArray,
        colors: colorsArray,
        chart: {
          width: 300,
          type: 'donut',
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const selectedLabel = this.topFiveCarrier.labels[config.dataPointIndex];
              this.redierectToTop(selectedLabel)
            }
          }
        },

        dataLabels: {
          enabled: false
        },

        labels: carrierNameArray,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              show: false
            }
          }
        }],
        legend: {
          position: 'right',
          offsetY: 0,
          height: 230,
        },

        plotOptions: {
          pie: {
            expandOnClick: false,

            donut: {

              size: '53%',

            }
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toFixed(2) + ' %';
            }
          }
        }
      };


      setTimeout(() => {
        this.leftRow.map((element, index) => {
          if (element.heading === 'Top 5 Carriers For User') {
            if (document.getElementById('leftGraph' + index)) {
              document.getElementById('leftGraph' + index).innerHTML = '';
            }
            this.getTopFiveCarrierData('leftGraph' + index, 'new', '', '', this.topFiveCarrier)
          }
        })
        this.rightRow.map((element, index) => {
          if (element.heading === 'Top 5 Carriers For User') {
            if (document.getElementById("rightGraph" + index)) {
              document.getElementById("rightGraph" + index).innerHTML = '';
            }
            this.getTopFiveCarrierData('rightGraph' + index, 'new', '', '', this.topFiveCarrier)
          }
        })
      }, 200)
      this.closeModel()

    })



    //  Regionwise Pie Chart Generation









    //end  Regionwise Pie Chart Generation
    let url1 = ServerURLS.getGraphDataOfQuotesPerMonth.action + '?fromDate=' + fromDate + '&toDate=' + toDate;
    let promise1 = this.scHttpService.getHttpService(url1, '');
    promise1.then((res: any) => {
      let executedQuoteTime = [];
      let totalQuoteTime = [];
      let yearArray1 = Object.keys(res.executedQuotesPerMonth)
      let yearArray2 = Object.keys(res.quotePerMonth)
      yearArray1.map((element) => {
        res.executedQuotesPerMonth[element].map((element2) => {
          executedQuoteTime.push(element2)
        })
      })
      yearArray2.map((element1) => {
        res.quotePerMonth[element1].map((element2) => {
          totalQuoteTime.push(element2)
        })
      })

      let commanElementArray = [];
      let timeArray = [];
      if (totalQuoteTime.length > executedQuoteTime.length) {
        totalQuoteTime.map((element) => {
          executedQuoteTime.map((element2) => {
            if (element.year === element2.year && element.month === element2.month) {
              commanElementArray.push(element)
            }
          })
        })
      } else if (totalQuoteTime.length < executedQuoteTime.length) {
        executedQuoteTime.map((element1) => {
          totalQuoteTime.map((element2) => {
            if (element1.year === element2.year && element1.month === element2.month) {
              commanElementArray.push(element1)
            }
          })
        })
      } else {
        totalQuoteTime.map((element) => {
          executedQuoteTime.map((element2) => {
            if (element.year === element2.year && element.month === element2.month) {
              commanElementArray.push(element)
            }
          })
        })
      }
      executedQuoteTime.map((element) => {
        let data = commanElementArray.filter(data => data.year === element.year && data.month === element.month);
        if (data.length === 0) {
          timeArray.push(element)
        }
      })
      totalQuoteTime.map((element) => {
        let data = commanElementArray.filter(data => data.year === element.year && data.month === element.month);
        if (data.length === 0) {
          timeArray.push(element)
        }
      })
      let direction = 1;
      timeArray = timeArray.concat(commanElementArray);
      let finalTimeArray = [];
      let finalObjectedArray = [];
      if (yearArray1.length > yearArray2.length) {
        yearArray1.map((element) => {
          let data = [];
          timeArray.map((element1) => {
            if (parseInt(element) === parseInt(element1.year)) {
              data.push(element1)
            }
          })
          data.sort(function (a, b) {
            if (parseFloat(a['month']) < parseFloat(b['month'])) {
              return -1 * direction;
            }
            else if (parseFloat(a['month']) > parseFloat(b['month'])) {
              return 1 * direction;
            }
            else {
              return 0;
            }
          });
          finalObjectedArray = finalObjectedArray.concat(data);
          data.forEach((element) => {
            finalTimeArray.push(this.setMonthInQuotePerMonthGraph(element.month) + " '" + element.year.slice(-2))
          })

        })
      } else if (yearArray2.length > yearArray1.length) {
        yearArray2.map((element) => {
          let data = [];
          timeArray.map((element1) => {
            if (parseInt(element) === parseInt(element1.year)) {
              data.push(element1)
            }
          })
          data.sort(function (a, b) {
            if (parseFloat(a['month']) < parseFloat(b['month'])) {
              return -1 * direction;
            }
            else if (parseFloat(a['month']) > parseFloat(b['month'])) {
              return 1 * direction;
            }
            else {
              return 0;
            }
          });
          finalObjectedArray = finalObjectedArray.concat(data);
          data.forEach((element) => {
            finalTimeArray.push(this.setMonthInQuotePerMonthGraph(element.month) + " '" + element.year.slice(-2))
          })

        })
      } else {
        yearArray1.map((element) => {
          let data = [];
          timeArray.map((element1) => {
            if (parseInt(element) === parseInt(element1.year)) {
              data.push(element1)
            }
          })
          data.sort(function (a, b) {
            if (parseFloat(a['month']) < parseFloat(b['month'])) {
              return -1 * direction;
            }
            else if (parseFloat(a['month']) > parseFloat(b['month'])) {
              return 1 * direction;
            }
            else {
              return 0;
            }
          });
          finalObjectedArray = finalObjectedArray.concat(data);
          data.forEach((element) => {
            finalTimeArray.push(this.setMonthInQuotePerMonthGraph(element.month) + " '" + element.year.slice(-2))
          })

        })
      }
      let finalTotalQuoteArray = [];
      let finalExecutedQuoteArray = [];

      finalObjectedArray.map((element) => {
        let data = executedQuoteTime.filter(data => data.month === element.month && data.year === element.year);
        if (data.length === 0) {
          finalExecutedQuoteArray.push(0);
        } else {
          finalExecutedQuoteArray.push(parseInt(data[0].quoteCount))
        }
        let data1 = totalQuoteTime.filter(data => data.month === element.month && data.year === element.year);
        if (data1.length === 0) {
          finalTotalQuoteArray.push(0)
        } else {
          finalTotalQuoteArray.push(parseInt(data1[0].quoteCount))
        }
      })


      let finalFailedCancelledArray = [];

      let failedCancelledQuoteTime = [];
      let failedCancelledArray = Object.keys(res.failedCancelledPerMonth);
      failedCancelledArray.map((element) => {
        res.failedCancelledPerMonth[element].map((element2) => {
          failedCancelledQuoteTime.push(element2);
        });
      });

      finalObjectedArray.forEach((element) => {
        let data = failedCancelledQuoteTime.find(data => data.month === element.month && data.year === element.year);
        if (data) {
          finalFailedCancelledArray.push(parseInt(data.quoteCount));
        } else {
          finalFailedCancelledArray.push(0);
        }
      });

      let filterValue = this.graphFilterForm.get('filterName').value;
      if (filterValue === 'LAST_3_MONTHS') {
        let length = 3;
        if (finalTotalQuoteArray.length > length || finalExecutedQuoteArray.length > length) {
          finalTotalQuoteArray.splice(0, finalTotalQuoteArray.length - 3);
          finalExecutedQuoteArray.splice(0, finalExecutedQuoteArray.length - 3);
          finalFailedCancelledArray.splice(0, finalFailedCancelledArray.length - 3)

          finalTimeArray.splice(0, finalTimeArray.length - 3)
        }
      } else if (filterValue === 'LAST_6_MONTHS') {
        let length = 6;
        if (finalTotalQuoteArray.length > length || finalExecutedQuoteArray.length > length) {
          finalTotalQuoteArray.splice(0, finalTotalQuoteArray.length - 6);
          finalExecutedQuoteArray.splice(0, finalExecutedQuoteArray.length - 6);
          finalFailedCancelledArray.splice(0, finalFailedCancelledArray.length - 6)

          finalTimeArray.splice(0, finalTimeArray.length - 6)
        }
      } else if (filterValue === 'LAST_9_MONTHS') {
        let length = 9;
        if (finalTotalQuoteArray.length > length || finalExecutedQuoteArray.length > 9) {
          finalTotalQuoteArray.splice(0, finalTotalQuoteArray.length - 9);
          finalExecutedQuoteArray.splice(0, finalExecutedQuoteArray.length - 9);
          finalFailedCancelledArray.splice(0, finalFailedCancelledArray.length - 9)

          finalTimeArray.splice(0, finalTimeArray.length - 9)
        }
      } else if (filterValue === 'LAST_YEAR') {
        let length = 12
        if (finalTotalQuoteArray.length > length || finalExecutedQuoteArray.length > length) {
          finalTotalQuoteArray.splice(0, finalTotalQuoteArray.length - 12);
          finalExecutedQuoteArray.splice(0, finalExecutedQuoteArray.length - 12)
          finalFailedCancelledArray.splice(0, finalFailedCancelledArray.length - 12)

          finalTimeArray.splice(0, finalTimeArray.length - 12)
        }
      }




      let width = '';
      if (finalTotalQuoteArray.length <= 3 && finalExecutedQuoteArray.length <= 3) {
        width = '37%'
      } else if (finalTotalQuoteArray.length <= 6 && finalExecutedQuoteArray.length <= 6) {
        width = '73%'
      } else if (finalTotalQuoteArray.length <= 9 && finalExecutedQuoteArray.length <= 9) {
        width = '60%'
      } else if (finalTotalQuoteArray.length <= 12 && finalExecutedQuoteArray.length <= 12) {
        width = '70%'
      }




      const totalFailedQuote = finalFailedCancelledArray.reduce((total, current) => total + current, 0);
      this.failedQuotes = totalFailedQuote;
      this.quoteTotalData = finalTotalQuoteArray.reduce((acc, curr) => acc + curr, 0);
      this.executedQuoteTotalData = finalExecutedQuoteArray.reduce((acc, curr) => acc + curr, 0)


      this.quotation = {
        series: [
          {

            data: finalTotalQuoteArray,
            name: "Total Quotes"
          },
          {


            data: finalExecutedQuoteArray,
            name: 'Executed Quotes'
          },
          {
            data: finalFailedCancelledArray,
            name: 'Failed/Cancelled Quotes'
          }
        ],
        chart: {
          type: "bar",
          height: 260,
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const seriesIndex = config.seriesIndex;
              const dataPointIndex = config.dataPointIndex;
              const monthName = chartContext.opts.xaxis.categories[dataPointIndex];
              const seriesName = chartContext.opts.series[seriesIndex].name;

              this.redirectToQuoteHistory(monthName, seriesName)
            }
          }
        },
        colors: ['#46B85B', '#5371FF', '#ff0000'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: width,
            dataLabels: {
              position: "top"
            }
          }
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#46B85B"]
          }
        },
        stroke: {
          show: true,
          width: 3,
          colors: ["#fff"]
        },
        xaxis: {
          categories: finalTimeArray
        }
      };
      setTimeout(() => {

        this.leftRow.map((element, index) => {
          if (element.heading === 'Quotes Per Month') {
            if (document.getElementById('leftGraph' + index)) {
              document.getElementById('leftGraph' + index).innerHTML = '';
            }
            this.getQuotationPerMonth('leftGraph' + index, 'new', '', '', this.quotation);
          }
        })
        this.rightRow.map((element, index) => {
          if (element.heading === 'Quotes Per Month') {
            if (document.getElementById('rightGraph' + index)) {
              document.getElementById('rightGraph' + index).innerHTML = '';
            }
            this.getQuotationPerMonth('rightGraph' + index, 'new', '', '', this.quotation)
          }
        })

      }, 400)



    })



    let url2 = ServerURLS.getGraphDataOfUserAccounts.action + '?fromDate=' + fromDate + '&toDate=' + toDate;
    let promise2 = this.scHttpService.getHttpService(url2, '');
    promise2.then((res: any) => {
      this.activeUserTotal = res.totalNewUserAccounts;
      this.inActiveUserTotal = res.totalInActiveUserAccounts
      let inActiveUserTime = [];
      let activeUserTime = [];
      let yearArray1 = Object.keys(res.inActiveUserAccountsPerMonth);
      let yearArray2 = Object.keys(res.newUserAccountsPerMonth);
      yearArray1.map((element1) => {
        res.inActiveUserAccountsPerMonth[element1].map((element2) => {
          inActiveUserTime.push(element2)
        })
      })
      yearArray2.map((element1) => {
        res.newUserAccountsPerMonth[element1].map((element2) => {
          activeUserTime.push(element2)
        })
      })
      let commanElementArray = [];
      let timeArray = []
      //  
      if (activeUserTime.length > inActiveUserTime.length) {
        activeUserTime.map((element, index) => {
          inActiveUserTime.map((element2) => {
            if (element.year === element2.year && element.month === element2.month) {
              commanElementArray.push(element)
            }
          })
        })
      } else if (activeUserTime.length < inActiveUserTime.length) {
        inActiveUserTime.map((element, index) => {
          activeUserTime.map((element2) => {
            if (element.year === element2.year && element.month === element2.month) {
              commanElementArray.push(element)
            }
          })
        })
      } else {
        activeUserTime.map((element, index) => {
          inActiveUserTime.map((element2) => {
            if (element.year === element2.year && element.month === element2.month) {
              commanElementArray.push(element)
            }
          })
        })
      }
      inActiveUserTime.map((element) => {
        let data = commanElementArray.filter(data => data.year === element.year && data.month === element.month);
        if (data.length === 0) {
          timeArray.push(element)
        }
      })
      activeUserTime.map((element) => {
        let data = commanElementArray.filter(data => data.year === element.year && data.month === element.month);
        if (data.length === 0) {
          timeArray.push(element)
        }
      })
      let direction = 1;
      timeArray = timeArray.concat(commanElementArray);
      let finalTimeArray = []
      let finalObjectedArray = []

      if (yearArray1.length > yearArray2.length) {
        yearArray1.map((element) => {
          let data = [];
          timeArray.map((element1) => {
            if (parseInt(element) === parseInt(element1.year)) {
              data.push(element1)
            }
          })
          data.sort(function (a, b) {
            if (parseFloat(a['month']) < parseFloat(b['month'])) {
              return -1 * direction;
            }
            else if (parseFloat(a['month']) > parseFloat(b['month'])) {
              return 1 * direction;
            }
            else {
              return 0;
            }
          });
          finalObjectedArray = finalObjectedArray.concat(data)
          data.forEach((element) => {
            finalTimeArray.push(this.setMonthInQuotePerMonthGraph(element.month) + " '" + element.year.slice(-2))
          })

        })
      } else if (yearArray1.length < yearArray2.length) {
        yearArray2.map((element) => {
          let data = [];
          timeArray.map((element1) => {
            if (parseInt(element) === parseInt(element1.year)) {
              data.push(element1)
            }
          })
          data.sort(function (a, b) {
            if (parseFloat(a['month']) < parseFloat(b['month'])) {
              return -1 * direction;
            }
            else if (parseFloat(a['month']) > parseFloat(b['month'])) {
              return 1 * direction;
            }
            else {
              return 0;
            }
          });
          finalObjectedArray = finalObjectedArray.concat(data)
          data.forEach((element) => {
            finalTimeArray.push(this.setMonthInQuotePerMonthGraph(element.month) + " '" + element.year.slice(-2))
          })

        })
      } else {
        yearArray2.map((element) => {
          let data = [];
          timeArray.map((element1) => {
            if (parseInt(element) === parseInt(element1.year)) {
              data.push(element1)
            }
          })
          data.sort(function (a, b) {
            if (parseFloat(a['month']) < parseFloat(b['month'])) {
              return -1 * direction;
            }
            else if (parseFloat(a['month']) > parseFloat(b['month'])) {
              return 1 * direction;
            }
            else {
              return 0;
            }
          });
          finalObjectedArray = finalObjectedArray.concat(data)
          data.forEach((element) => {
            finalTimeArray.push(this.setMonthInQuotePerMonthGraph(element.month) + " '" + element.year.slice(-2))
          })

        })
      }

      let finalActiveUserArray = [];
      let finalInActiveUserArray = [];
      finalObjectedArray.map((element) => {
        let data = inActiveUserTime.filter(data => data.month === element.month && data.year === element.year);
        if (data.length === 0) {
          finalInActiveUserArray.push(0)
        } else {
          finalInActiveUserArray.push(parseInt(data[0].count))
        }
        let data2 = activeUserTime.filter(data => data.month === element.month && data.year === element.year);
        if (data2.length === 0) {
          finalActiveUserArray.push(0)
        } else {
          finalActiveUserArray.push(parseInt(data2[0].count))
        }
      })


      let filterValue = this.graphFilterForm.get('filterName').value;
      if (filterValue === 'LAST_3_MONTHS') {
        let length = 3;
        if (finalActiveUserArray.length > length || finalInActiveUserArray.length > 3) {
          finalActiveUserArray.splice(0, finalActiveUserArray.length - 3);
          finalInActiveUserArray.splice(0, finalInActiveUserArray.length - 3);
          finalTimeArray.splice(0, finalTimeArray.length - 3)
        }
      } else if (filterValue === 'LAST_6_MONTHS') {
        let length = 6;
        if (finalActiveUserArray.length > length || finalActiveUserArray.length > length) {
          finalActiveUserArray.splice(0, finalActiveUserArray.length - 6);
          finalInActiveUserArray.splice(0, finalInActiveUserArray.length - 6);
          finalTimeArray.splice(0, finalTimeArray.length - 6)
        }
      } else if (filterValue === 'LAST_9_MONTHS') {
        let length = 9;
        if (finalActiveUserArray.length > length || finalInActiveUserArray.length > length) {
          finalActiveUserArray.splice(0, finalActiveUserArray.length - 9);
          finalInActiveUserArray.splice(0, finalInActiveUserArray.length - 9);
          finalTimeArray.splice(0, finalTimeArray.length - 9)
        }
      } else if (filterValue === 'LAST_YEAR') {
        let length = 12;
        if (finalActiveUserArray.length > length || finalInActiveUserArray.length > length) {
          finalActiveUserArray.splice(0, finalActiveUserArray.length - 12);
          finalInActiveUserArray.splice(0, finalInActiveUserArray.length - 12);
          finalTimeArray.splice(0, finalTimeArray.length - 12)
        }
      }
      let width = '';
      if (finalActiveUserArray.length <= 3 && finalInActiveUserArray.length <= 3) {
        width = '24%'
      } else if (finalActiveUserArray.length <= 6 && finalInActiveUserArray.length <= 6) {
        width = '48%'
      } else if (finalActiveUserArray.length <= 9 && finalInActiveUserArray.length <= 9) {
        width = '60%'
      } else if (finalActiveUserArray.length <= 12 && finalInActiveUserArray.length <= 12) {
        width = '70%'
      }

      this.activeUserTotal = finalActiveUserArray.reduce((acc, curr) => acc + curr, 0);
      this.inActiveUserTotal = finalInActiveUserArray.reduce((acc, curr) => acc + curr, 0)

      this.userAccount = {
        series: [
          {

            data: finalActiveUserArray,
            name: "New User"
          },
          {


            data: finalInActiveUserArray,
            name: 'Inactive User'
          }
        ],
        chart: {
          type: "bar",
          height: 260,
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const seriesIndex = config.seriesIndex;
              const dataPointIndex = config.dataPointIndex;
              const monthName = chartContext.opts.xaxis.categories[dataPointIndex];
              const seriesName = chartContext.opts.series[seriesIndex].name;
              this.redirectToCustomer(monthName, seriesName)
            }
          }
        },
        colors: ['#46B85B', '#5371FF'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: width,
            dataLabels: {
              position: "top"
            }
          }
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#46B85B"]
          }
        },
        stroke: {
          show: true,
          width: 3,
          colors: ["#fff"]
        },
        xaxis: {
          categories: finalTimeArray
        }
      };

      setTimeout(() => {
        this.leftRow.map((element, index) => {
          if (element.heading === 'User Accounts') {
            if (document.getElementById('leftGraph' + index)) {
              document.getElementById('leftGraph' + index).innerHTML = '';
            }
            this.getUserAccountData('leftGraph' + index, 'new', '', '', this.userAccount);
          }
        })
        this.rightRow.map((element, index) => {
          if (element.heading === 'User Accounts') {
            if (document.getElementById('rightGraph' + index)) {
              document.getElementById('rightGraph' + index).innerHTML = '';
            }
            this.getUserAccountData('rightGraph' + index, 'new', '', '', this.userAccount)
          }
        })
      }, 300)



    })
    let url5 = ServerURLS.getGraphDateOfShipmentsPermonths.action + '?fromDate=' + fromDate + '&toDate=' + toDate;
    let promise5 = this.scHttpService.getHttpService(url5, '');
    promise5.then((res: any) => {
      this.userShipmentPerMonth = {}
      let valueArray = [];
      let monthArray = [];
      let yearArray = Object.keys(res.quotePerMonth);

      yearArray.forEach((element1) => {
        res.quotePerMonth[element1].map((element2) => {

          valueArray.push(parseInt(element2.count));
          monthArray.push(this.setMonthInQuotePerMonthGraph(element2.month) + " '" + element2.year.slice(-2));
        })
      })

      let filterValue = this.graphFilterForm.get('filterName').value;
      if (filterValue === 'LAST_3_MONTHS') {
        let length = 3;
        if (valueArray.length > length) {
          valueArray.splice(0, valueArray.length - 3);
          monthArray.splice(0, monthArray.length - 3)
        }
      } else if (filterValue === 'LAST_6_MONTHS') {
        let length = 6;
        if (valueArray.length > length) {
          valueArray.splice(0, valueArray.length - 6);
          monthArray.splice(0, monthArray.length - 6);
        }
      } else if (filterValue === 'LAST_9_MONTHS') {
        let length = 9;
        if (valueArray.length > length) {
          valueArray.splice(0, valueArray.length - 9);
          monthArray.splice(0, monthArray.length - 9)
        }
      } else if (filterValue === 'LAST_YEAR') {
        let length = 12;
        if (valueArray.length > length) {
          valueArray.splice(0, valueArray.length - 12);
          monthArray.splice(0, monthArray.length - 12)
        }
      }


      this.userShipmentPerMonth = {
        series: [{
          name: 'Shipments',
          data: valueArray
        }],
        chart: {
          type: 'area',
          height: 270,
          events: {
            click: (event, chartContext, config) => {
              const dataIndex = config.dataPointIndex;
              if (dataIndex !== -1) {
                const monthYear = monthArray[dataIndex];
                const count = valueArray[dataIndex];
                this.redirectToShipmentList(monthYear, count);
              }
            }
          }
        },
        dataLabels: {
          enabled: false,

        }, colors: ['#5371FF'],
        stroke: {
          curve: 'straight'
        },

        title: {

          align: 'left',
          style: {
            fontSize: '14px'
          }
        },
        xaxis: {
          categories: monthArray
          // type: 'datetime',
          // axisBorder: {
          //   show: false
          // },
          // axisTicks: {
          //   show: false
          // }
        },
        yaxis: {
          tickAmount: 4,
          floating: false,

          labels: {
            style: {
              colors: '#8e8da4',
            },
            offsetY: -7,
            offsetX: 0,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 0.5
        },
        //   tooltip: {
        //     x: {
        //       format: "yyyy",
        //     },
        //     fixed: {
        //       enabled: false,
        //       position: 'topRight'
        //     }
        //   },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30
            }
          },
          padding: {
            left: 20
          }
        }
      };

      setTimeout(() => {
        this.leftRow.map((element, index) => {
          if (element.heading === 'User Shipment Per Month') {
            if (document.getElementById('leftGraph' + index)) {
              document.getElementById('leftGraph' + index).innerHTML = ''
            }
            this.getshipmentPerMonth('leftGraph' + index, 'new', '', '', this.userShipmentPerMonth);
          }
        })
        this.rightRow.map((element, index) => {
          if (element.heading === 'User Shipment Per Month') {
            if (document.getElementById('rightGraph' + index)) {
              document.getElementById('rightGraph' + index).innerHTML = '';
            }
            this.getshipmentPerMonth('rightGraph' + index, 'new', '', '', this.userShipmentPerMonth)
          }
        })
      }, 300)
      this.closeModel();

    })
    let url6 = ServerURLS.getGraphDateOfCourierAndLTLPermonths.action + '?fromDate=' + fromDate + '&toDate=' + toDate;
    let promise6 = this.scHttpService.getHttpService(url6, '');
    promise6.then((response: any) => {
      this.courierLTLPerMonth = {}
      let courierArray = [];
      let ltlArray = [];
      let monthArray = [];
      let yearArray = Object.keys(response.quotePerMonth);


      yearArray.forEach((year) => {
        response.quotePerMonth[year].forEach((element) => {
          courierArray.push(parseInt(element.courierCount));
          ltlArray.push(parseInt(element.ltlCount));
          monthArray.push(this.setMonthInQuotePerMonthGraph(element.month) + " '" + element.year.slice(-2));
        });
      });

      let filterValue = this.graphFilterForm.get('filterName').value;
      let length;
      switch (filterValue) {
        case 'LAST_3_MONTHS':
          length = 3;
          break;
        case 'LAST_6_MONTHS':
          length = 6;
          break;
        case 'LAST_9_MONTHS':
          length = 9;
          break;
        case 'LAST_YEAR':
          length = 12;
          break;
        default:


          length = courierArray.length;
          break;
      }

      if (courierArray.length > length) {
        courierArray.splice(0, courierArray.length - length);
        ltlArray.splice(0, ltlArray.length - length);
        monthArray.splice(0, monthArray.length - length);
      }

      let width = '';
      if (courierArray.length <= 3 && ltlArray.length <= 3) {
        width = '24%'
      } else if (courierArray.length <= 6 && ltlArray.length <= 6) {
        width = '48%'
      } else if (courierArray.length <= 9 && ltlArray.length <= 9) {
        width = '60%'
      } else if (courierArray.length <= 12 && ltlArray.length <= 12) {
        width = '70%'
      }

      this.totalLTLCount = ltlArray.reduce((acc, curr) => acc + curr, 0);
      this.totalCouriers = courierArray.reduce((acc, curr) => acc + curr, 0);
      this.courierLTLPerMonth = {
        series: [

          {
            name: 'Couriers',
            data: courierArray
          },
          {
            name: 'LTL',
            data: ltlArray,

          }
        ],
        chart: {
          type: "bar",
          height: 260,
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const seriesIndex = config.seriesIndex;
              const dataPointIndex = config.dataPointIndex;
              const monthName = chartContext.opts.xaxis.categories[dataPointIndex];
              const seriesName = chartContext.opts.series[seriesIndex].name;
              this.redirectToOrderHistory(monthName, seriesName)
            }
          }
        },
        colors: ['#5371FF', '#ff0000'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: width,
            dataLabels: {
              position: "top"
            }
          }
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#46B85B"]
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["#fff"]
        },
        xaxis: {
          categories: monthArray
        }
      };


      setTimeout(() => {
        this.leftRow.map((element, index) => {
          if (element.heading === 'Courier / LTL executed Per Month') {
            if (document.getElementById('leftGraph' + index)) {
              document.getElementById('leftGraph' + index).innerHTML = ''
            }
            this.getshipmentPerMonthLTL('leftGraph' + index, 'new', '', '', this.courierLTLPerMonth);
          }
        })
        this.rightRow.map((element, index) => {
          if (element.heading === 'Courier / LTL executed Per Month') {
            if (document.getElementById('rightGraph' + index)) {
              document.getElementById('rightGraph' + index).innerHTML = '';
            }
            this.getshipmentPerMonthLTL('rightGraph' + index, 'new', '', '', this.courierLTLPerMonth)
          }
        })
      }, 300)
      this.closeModel();

    })
    // this.getRegionOfShipping('rightGraph0','new');
    // this.getQuoteByDevices('leftGraph3','new');
    // this.getUserActivityData('rightGraph3','new')
    this.getShipmentDeliverByRegion(fromDate, toDate)
  }

  getShipmentDeliverByRegion(fromDate, toDate) {
    let url7: string = `${ServerURLS.getGraphDataOfShipmentByCountry.action}?fromDate=${fromDate}&toDate=${toDate}&metricType=SHIPMENTS_BY_REGION`;
  
    let promise7 = this.scHttpService.getHttpService(url7, '');
    promise7.then((res: any) => {
      this.shipmentCARegion = [];
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
  
      const data = res.details;
      const colorsArray = [
        '#F52D23', '#F5A623', '#5371FF', '#46B85B', '#23A9F5', '#5eb8ff',
        '#FF5733', '#33FF57', '#FF33A6', '#33A6FF', '#A633FF', '#FFD700',
        '#8A2BE2', '#20B2AA', '#FF4500', '#DA70D6', '#32CD32', '#6495ED',
        '#DC143C', '#00CED1'
      ];
  
      let seriesArray: number[] = [];
      let labelsArray: string[] = [];
      let colorIndex = 0;
  
      const countrySelectedValue = this.shipmentByRegionForm.get('country').value;
      const regionsArray = data.find(item => item.countryName.toLocaleLowerCase() === countrySelectedValue);
  
      if (!regionsArray || !regionsArray.regions || regionsArray.regions.length === 0) {
        console.warn("No data available for this region.");
        this.shipmentCARegion = [];
        
        // Set chart configuration for empty data
        const emptyChartConfig = {
          series: [],
          colors: [],
          chart: {
            width: 300,
            type: 'donut'
          },
          labels: [],
          legend: {
            position: 'right',
            offsetY: 0,
            height: 230
          }
        };
  
        setTimeout(() => {
          this.leftRow.map((element, index) => {
            if (element.heading === 'Shipment Deliver by Region') {
              if (document.getElementById('leftGraph' + index)) {
                document.getElementById('leftGraph' + index).innerHTML = '<p style="text-align: center;">No data available for this region</p>';
              }
            }
          });
          this.rightRow.map((element, index) => {
            if (element.heading === 'Shipment Deliver by Region') {
              if (document.getElementById('rightGraph' + index)) {
                document.getElementById('rightGraph' + index).innerHTML = '<p style="text-align: center;">No data available for this region</p>';
              }
            }
          });
        }, 0);

        this.toastr.error('No data available for this region')

  
        return;
      }
  
      regionsArray.regions.forEach((region: any) => {
        labelsArray.push(region.name);
        seriesArray.push(region.percentage);
  
        this.shipmentCARegion.push({
          color: colorsArray[colorIndex % colorsArray.length], // Cycle through colors
          name: region.name,
          count: region.count,
          percentage: region.percentage,
        });
  
        colorIndex++;
      });
  
      const chartConfigforCA = {
        series: seriesArray,
        colors: colorsArray,
        chart: {
          width: 300,
          type: 'donut',
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const selectedLabel = chartConfigforCA.labels[config.dataPointIndex];
              this.redirectToRegionShipment(selectedLabel);
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        labels: labelsArray,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }
        ],
        legend: {
          position: 'right',
          offsetY: 0,
          height: 230
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: '53%'
            }
          }
        },
        tooltip: {
          y: {
            formatter: function (val: number) {
              return `${val}% Shipments`;
            }
          }
        }
      };
  
      setTimeout(() => {
        this.leftRow.map((element, index) => {
          if (element.heading === 'Shipment Deliver by Region') {
            if (document.getElementById('leftGraph' + index)) {
              document.getElementById('leftGraph' + index).innerHTML = '';
            }
            this.getCAShipment('leftGraph' + index, 'new', '', '', chartConfigforCA);
          }
        });
        this.rightRow.map((element, index) => {
          if (element.heading === 'Shipment Deliver by Region') {
            if (document.getElementById('rightGraph' + index)) {
              document.getElementById('rightGraph' + index).innerHTML = '';
            }
            this.getCAShipment('rightGraph' + index, 'new', '', '', chartConfigforCA);
          }
        });
      }, 200);
  
      this.closeModel();
    }).catch((error: any) => {
      console.error("Error fetching data: ", error);
    });
  }

  
  getshipmentPerMonth(id, mode, row?, index?, obj?) {
    setTimeout(() => {
      if (document.getElementById(id)) {
        document.getElementById(id).innerHTML = ''
      }
      if (document.querySelector("#" + id)) {
        let chart1 = new ApexCharts(document.querySelector("#" + id), obj);
        chart1.render()
      }
      // let chart2=new ApexCharts(document.querySelector('#'+id2),this.options2)
    }, 200)


    if (mode === 'change' && row === 'two') {
      let obj = this.staticLeftRow.filter(data => data.heading === 'User Shipment Per Month');
      this.staticLeftRow = this.staticLeftRow.filter(data => data.heading !== 'User Shipment Per Month')
      this.staticRightRow.splice(index, 0, obj[0])
    } else if (mode === 'change' && row === 'one') {
      let obj = this.staticRightRow.filter(data => data.heading === 'User Shipment Per Month');
      this.staticRightRow = this.staticRightRow.filter(data => data.heading != 'User Shipment Per Month');
      this.staticLeftRow.splice(index, 0, obj[0])
    }
  }



  redirectToCountyShipment(series: string) {

    const shippingCountryMap = {
      'Canada': 'CA',
      'United States': 'US',
      'Trans-Border': 'TB'
    };



    const shippingCountry = shippingCountryMap[series];

    if (shippingCountry) {
      if (this.fromDate && this.toDate) {
        this.router.navigate(
          ['/shipcarte/orders/filter', this.fromDate, this.toDate, 'isDashBoardView'],
          {
            queryParams: { shippingCountry: shippingCountry }
          }
        );
      } else {
        console.error('fromDate and toDate must be defined');
      }

    }
  }



  redirectToRegionShipment(series: string) {

    const shippingCountryMap = {
      'Canada': 'CA',
      'United States': 'US',
      'Trans-Border': 'TB'
    };




    if (series) {
      if (this.fromDate && this.toDate) {
        this.router.navigate(
          ['/shipcarte/orders/filter', this.fromDate, this.toDate, 'isDashBoardView'],
          {
            queryParams: { shippingRegion: series }
          }
        );
      } else {
        console.error('fromDate and toDate must be defined');
      }

    }
  }

  redirectToCustomer(monthYear: string, series: string): void {
    const [month, year] = monthYear.split(" '");
    let fullYear = '20' + year;
    const { startDate, endDate } = this.getDateRangeForMonth(month, fullYear);
    let barStatus;
    if (series == 'New User') {
      barStatus = 'Active';
      this.router.navigate(
        ['/shipcarte/customer/list/filter', startDate, endDate],
        {
          queryParams: { status: barStatus }
        }
      );
    }
    else if (series == 'Inactive User') {
      barStatus = 'Inactive';
      this.router.navigate(
        ['/shipcarte/customer/list/filter', startDate, endDate],
        {
          queryParams: { status: barStatus }
        }
      );
    }




  }



  redirectToQuoteHistory(monthYear: string, series: string): void {
    const [month, year] = monthYear.split(" '");
    let fullYear = '20' + year;
    const { startDate, endDate } = this.getDateRangeForMonth(month, fullYear);
    let quoteStatus;
    if (series == 'Executed Quotes') {
      quoteStatus = 'executed';
      this.router.navigate(
        ['/shipcarte/quoteHistory/allQuotes/view', startDate, endDate],
        {
          queryParams: { status: quoteStatus }
        }
      );
    }
    else if (series == 'Total Quotes') {
      quoteStatus = 'all';
      this.router.navigate(
        ['/shipcarte/quoteHistory/allQuotes/view', startDate, endDate],
        {
          queryParams: { status: quoteStatus }
        }
      );
    }


    else if (series == 'Failed/Cancelled Quotes') {
      quoteStatus = 'UNSUCCESSFUL'
      this.router.navigate(
        ['/shipcarte/quoteHistory/allQuotes/view', startDate, endDate],
        {
          queryParams: { status: quoteStatus }
        }
      );
    }

  }


  redirectToOrderHistory(monthYear: string, series: string): void {
    const [month, year] = monthYear.split(" '");
    let fullYear = '20' + year;
    const { startDate, endDate } = this.getDateRangeForMonth(month, fullYear);
    let quoteStatus;
    if (series == 'Couriers') {
      quoteStatus = 'CRS';
      this.router.navigate(
        ['/shipcarte/orders/filter', startDate, endDate, 'isDashBoardView'],
        {
          queryParams: { status: quoteStatus }
        }
      );
    }
    else if (series == 'LTL') {
      quoteStatus = 'LTL';
      this.router.navigate(
        ['/shipcarte/orders/filter', startDate, endDate, 'isDashBoardView'],
        {
          queryParams: { status: quoteStatus }
        }
      );
    }


  }


  redierectToTop(label: string) {
    if (label === 'Others') {
      if (this.carrierIdData && Object.keys(this.carrierIdData).length > 0) {
        var carrierData = Object.values(this.carrierIdData).join(',');

        if (this.fromDate && this.toDate) {

          this.router.navigate(['/shipcarte/orders/filter', this.fromDate, this.toDate, 'isDashBoardView'], {
            queryParams: { carrierId: carrierData }
          }).then(success => {
            if (success) {
            } else {
            }
          }).catch(error => {
            console.error('Navigation error:', error);
          });
        } else {
          console.error('fromDate or toDate is undefined');
        }
      } else {
        console.error('carrierIdData is not defined or is empty');
      }
    }

    else {
      this.router.navigate(['/shipcarte/orders/filter', this.fromDate, this.toDate, 'isDashBoardView'],
        {
          queryParams: { carrierId: this.carrierIdData[label] }
        }
      );
    }

  }



  redirectToShipmentList(monthYear: string, count: number): void {
    const [month, year] = monthYear.split(" '");
    let fullYear = '20' + year;
    const { startDate, endDate } = this.getDateRangeForMonth(month, fullYear);

    this.router.navigate(['/shipcarte/orders/filter', startDate, endDate, 'isDashBoardView']
    );
  }
  getDateRangeForMonth(month: string, year: string): { startDate: string, endDate: string } {
    const monthIndex = this.getMonthIndex(month);
    const yearInt = parseInt(year, 10);

    const startDate = `${yearInt}-${this.padZero(monthIndex + 1)}-01`;
    const endDate = `${yearInt}-${this.padZero(monthIndex + 1)}-${this.getLastDayOfMonth(monthIndex, yearInt)}`;


    return { startDate, endDate };
  }

  getMonthIndex(month: string): number {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
      "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    return monthNames.indexOf(month);
  }

  padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  getLastDayOfMonth(monthIndex: number, year: number): number {
    return new Date(year, monthIndex + 1, 0).getDate();
  }

  getshipmentPerMonthLTL(id, mode, row?, index?, obj?) {
    setTimeout(() => {
      if (document.getElementById(id)) {
        document.getElementById(id).innerHTML = ''
      }
      if (document.querySelector("#" + id)) {
        let chart1 = new ApexCharts(document.querySelector("#" + id), obj);
        chart1.render()
      }
      // let chart2=new ApexCharts(document.querySelector('#'+id2),this.options2)
    }, 200)


    if (mode === 'change' && row === 'two') {
      let obj = this.staticLeftRow.filter(data => data.heading === 'Courier / LTL executed Per Month');
      this.staticLeftRow = this.staticLeftRow.filter(data => data.heading !== 'Courier / LTL executed Per Month')
      this.staticRightRow.splice(index, 0, obj[0])
    } else if (mode === 'change' && row === 'one') {
      let obj = this.staticRightRow.filter(data => data.heading === 'Courier / LTL executed Per Month');
      this.staticRightRow = this.staticRightRow.filter(data => data.heading != 'Courier / LTL executed Per Month');
      this.staticLeftRow.splice(index, 0, obj[0])
    }
  }
  getRegionOfShipping(id, mode, row?, index?, obj?) {
    if (document.getElementById(id)) {
      document.getElementById(id).innerHTML = ''
    }

    let chart1 = new ApexCharts(document.querySelector("#" + id), obj);
    // let chart2=new ApexCharts(document.querySelector('#'+id2),this.options2)
    chart1.render()
    if (mode === 'change' && row === 'two') {
      let obj = this.staticLeftRow.filter(data => data.heading === 'Regions Of Shipping');
      this.staticLeftRow = this.staticLeftRow.filter(data => data.heading !== 'Regions Of Shipping')
      this.staticRightRow.splice(index, 0, obj[0])
    } else if (mode === 'change' && row === 'one') {
      let obj = this.staticRightRow.filter(data => data.heading === 'Regions Of Shipping');
      this.staticRightRow = this.staticRightRow.filter(data => data.heading != 'Regions Of Shipping');
      this.staticLeftRow.splice(index, 0, obj[0])
    }
  }
  getQuotationPerMonth(id, mode, row?, index?, obj?) {
    setTimeout(() => {
      if (document.getElementById(id)) {
        document.getElementById(id).innerHTML = ''
      }

      let chart1 = new ApexCharts(document.querySelector("#" + id), obj);
      // let chart2=new ApexCharts(document.querySelector('#'+id2),this.options2)
      chart1.render()
    }, 200)
    if (mode === 'change' && row === 'two') {
      let obj = this.staticLeftRow.filter(data => data.heading === 'Quotes Per Month');
      this.staticLeftRow = this.staticLeftRow.filter(data => data.heading !== 'Quotes Per Month')
      this.staticRightRow.splice(index, 0, obj[0])
    } else if (mode === 'change' && row === 'one') {
      let obj = this.staticRightRow.filter(data => data.heading === 'Quotes Per Month');
      this.staticRightRow = this.staticRightRow.filter(data => data.heading != 'Quotes Per Month');
      this.staticLeftRow.splice(index, 0, obj[0])
    }
  }
  getCountryData(id, mode, row?, index?, obj?) {
    setTimeout(() => {
      if (document.getElementById(id)) {
        document.getElementById(id).innerHTML = ''
      }

      let chart1 = new ApexCharts(document.querySelector("#" + id), obj);
      // let chart2=new ApexCharts(document.querySelector('#'+id2),this.options2)
      chart1.render();
    }, 200)
    if (mode === 'change' && row === 'two') {
      let obj = this.staticLeftRow.filter(data => data.heading === 'All Shipments');
      this.staticLeftRow = this.staticLeftRow.filter(data => data.heading !== 'All Shipments')
      this.staticRightRow.splice(index, 0, obj[0])
    } else if (mode === 'change' && row === 'one') {
      let obj = this.staticRightRow.filter(data => data.heading === 'All Shipments');
      this.staticRightRow = this.staticRightRow.filter(data => data.heading != 'All Shipments');
      this.staticLeftRow.splice(index, 0, obj[0])
    }
  }
  getTopFiveCarrierData(id, mode, row?, index?, obj?) {
    setTimeout(() => {
      if (document.getElementById(id)) {
        document.getElementById(id).innerHTML = ''
      }
      if (document.querySelector('#' + id)) {
        let chart1 = new ApexCharts(document.querySelector('#' + id), obj)
        chart1.render();
      }
    }, 200)
    if (mode === 'change' && row === 'two') {
      let obj = this.staticLeftRow.filter(data => data.heading === 'Top 5 Carriers For User');
      this.staticLeftRow = this.staticLeftRow.filter(data => data.heading !== 'Top 5 Carriers For User')
      this.staticRightRow.splice(index, 0, obj[0])
    } else if (mode === 'change' && row === 'one') {
      let obj = this.staticRightRow.filter(data => data.heading === 'Top 5 Carriers For User');
      this.staticRightRow = this.staticRightRow.filter(data => data.heading != 'Top 5 Carriers For User');
      this.staticLeftRow.splice(index, 0, obj[0])
    }
  }



  getCAShipment(id, mode, row?, index?, obj?) {
    setTimeout(() => {
      if (document.getElementById(id)) {
        document.getElementById(id).innerHTML = ''
      }
      if (document.querySelector('#' + id)) {
        let chart1 = new ApexCharts(document.querySelector('#' + id), obj)
        chart1.render();
      }
    }, 200)
    if (mode === 'change' && row === 'two') {
      let obj = this.staticLeftRow.filter(data => data.heading === 'Shipment Deliver by Region');
      this.staticLeftRow = this.staticLeftRow.filter(data => data.heading !== 'Shipment Deliver by Region')
      this.staticRightRow.splice(index, 0, obj[0])
    } else if (mode === 'change' && row === 'one') {
      let obj = this.staticRightRow.filter(data => data.heading === 'Shipment Deliver by Region');
      this.staticRightRow = this.staticRightRow.filter(data => data.heading != 'Shipment Deliver by Region');
      this.staticLeftRow.splice(index, 0, obj[0])
    }
  }



  getUSAShipment(id, mode, row?, index?, obj?) {
    setTimeout(() => {
      if (document.getElementById(id)) {
        document.getElementById(id).innerHTML = ''
      }
      if (document.querySelector('#' + id)) {
        let chart1 = new ApexCharts(document.querySelector('#' + id), obj)
        chart1.render();
      }
    }, 200)
    if (mode === 'change' && row === 'two') {
      let obj = this.staticLeftRow.filter(data => data.heading === 'USA Region Shipments');
      this.staticLeftRow = this.staticLeftRow.filter(data => data.heading !== 'USA Region Shipments')
      this.staticRightRow.splice(index, 0, obj[0])
    } else if (mode === 'change' && row === 'one') {
      let obj = this.staticRightRow.filter(data => data.heading === 'USA Region Shipments');
      this.staticRightRow = this.staticRightRow.filter(data => data.heading != 'USA Region Shipments');
      this.staticLeftRow.splice(index, 0, obj[0])
    }
  }

  getUserAccountData(id, mode, row?, index?, obj?) {
    setTimeout(() => {
      if (document.getElementById(id)) {
        document.getElementById(id).innerHTML = ''
      }

      let chart = new ApexCharts(document.querySelector('#' + id), obj);
      chart.render();
    }, 200)
    if (mode === 'change' && row === 'two') {
      let obj = this.staticLeftRow.filter(data => data.heading === 'User Accounts');
      this.staticLeftRow = this.staticLeftRow.filter(data => data.heading !== 'User Accounts')
      this.staticRightRow.splice(index, 0, obj[0])
    } else if (mode === 'change' && row === 'one') {
      let obj = this.staticRightRow.filter(data => data.heading === 'User Accounts');
      this.staticRightRow = this.staticRightRow.filter(data => data.heading != 'User Accounts');
      this.staticLeftRow.splice(index, 0, obj[0])
    }
  }
  getQuoteByDevices(id, mode, row?, index?, obj?) {
    if (document.getElementById(id)) {
      document.getElementById(id).innerHTML = ''
    }

    let chart = new ApexCharts(document.querySelector('#' + id), obj);
    chart.render()
    if (mode === 'change' && row === 'two') {
      let obj = this.staticLeftRow.filter(data => data.heading === 'Quotes By  Device');
      this.staticLeftRow = this.staticLeftRow.filter(data => data.heading !== 'Quotes By  Device')
      this.staticRightRow.splice(index, 0, obj[0])
    } else if (mode === 'change' && row === 'one') {
      let obj = this.staticRightRow.filter(data => data.heading === 'Quotes By  Device');
      this.staticRightRow = this.staticRightRow.filter(data => data.heading != 'Quotes By  Device');
      this.staticLeftRow.splice(index, 0, obj[0])
    }
  }
  getUserActivityData(id, mode, row?, index?, obj?) {
    if (document.getElementById(id)) {
      document.getElementById(id).innerHTML = ''
    }

    let chart = new ApexCharts(document.querySelector('#' + id), obj);
    chart.render();
    if (mode === 'change' && row === 'two') {
      let obj = this.staticLeftRow.filter(data => data.heading === 'User Activity');
      this.staticLeftRow = this.staticLeftRow.filter(data => data.heading !== 'User Activity')
      this.staticRightRow.splice(index, 0, obj[0])
    } else if (mode === 'change' && row === 'one') {
      let obj = this.staticRightRow.filter(data => data.heading === 'User Activity');
      this.staticRightRow = this.staticRightRow.filter(data => data.heading != 'User Activity');
      this.staticLeftRow.splice(index, 0, obj[0])
    }
  }
  applyKeyIndicatorFilter(value) {
    this.getKeyIndicatorData(value);
  }
  selectCountry(event) {
    const filter = this.graphFilterForm.get('filterName').value;
    let month;
    if (filter === 'LAST_3_MONTHS') {
      month = 3
    } else if (filter === 'LAST_6_MONTHS') {
      month = 6
    } else if (filter === 'LAST_9_MONTHS') {
      month = 9
    } else if (filter === 'LAST_YEAR') {
      month = 12
    }
    let date = this.getFromDateAndToDate(month);

    this.getShipmentDeliverByRegion(date.fromDate, date.toDate)
  }
  dropItem(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {

      const arr = this.groups;
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);

      this.changeStaticArrayElement(event);
    }



  }
  changeStaticArrayElement(event, mode?) {
    let previousContainerId = parseInt(event.previousContainer.id);
    let currentContainerId = parseInt(event.container.id);
    let elementName;
    let removedElement;
    this.staticGroups.map((element1) => {
      if (element1.id === currentContainerId) {
        elementName = element1.items
      }
    })
    this.groups.map((element) => {
      if (element.id === currentContainerId) {
        let removalbeIndex = 0;
        element.items.map((element1, index) => {
          let arr = elementName.filter(data => data.heading === element1.heading);
          if (arr.length === 0) {
            removalbeIndex = index + 1;
          }
        })
        if (removalbeIndex > 0) {
          removedElement = element.items.splice(removalbeIndex, 1)
          this.groups.map((element1, index1) => {
            if (index1 === previousContainerId - 1) {
              element1.items.push(removedElement[0])
            }
          })
        }
      }
    })
    this.changeThe(previousContainerId, currentContainerId, event.currentIndex, event.previousIndex);
  }
  changeThe(preId, CurId, currentIndex, previousIndex) {
    let firstElement;
    let secondElement;
    this.staticGroups.map((element, index) => {
      if (element.id === preId) {
        element.items.map((ele, ind) => {
          if (ind === previousIndex) {
            firstElement = ele
          }
        })
      }
      if (element.id === CurId) {
        element.items.map((ele, ind) => {
          if (ind === currentIndex) {
            secondElement = ele
          }
        })
      }
    })
    this.staticGroups.map((element, index) => {
      if (element.id === preId) {
        element.items[previousIndex] = secondElement
      }
      if (element.id === CurId) {
        element.items[currentIndex] = firstElement
      }
    })
  }
  getConnectedList(): any[] {
    return this.groups.map(x => `${x.id}`);
  }
  applyGraphFilter(value) {
    let month;
    if (value === 'LAST_3_MONTHS') {
      month = 3
    } else if (value === 'LAST_6_MONTHS') {
      month = 6
    } else if (value === 'LAST_9_MONTHS') {
      month = 9
    } else if (value === 'LAST_YEAR') {
      month = 12
    }
    let date = this.getFromDateAndToDate(month);

    this.fromDate = date.fromDate
    this.toDate = date.toDate
    this.getGraphData(date.fromDate, date.toDate)
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      let id1 = '';
      let id2 = '';
      if (event.previousContainer.id != 'cdk-drop-list-1' && event.previousContainer.id != 'cdk-drop-list-2') {

        let one = event.previousContainer.id.split('-')
        let idone = one[one.length - 1];

        let two = event.container.id.split('-');
        let idtwo = two[two.length - 1];

        if (idone > idtwo) {
          id2 = event.previousContainer.id;
          id1 = event.container.id;
        } else {
          id2 = event.container.id;
          id1 = event.previousContainer.id
        }

      } else {
        id1 = 'cdk-drop-list-1';
        id2 = 'cdk-drop-list-2'
      }
      if (event.container.id != event.previousContainer.id) {
        if (event.previousContainer.id === id1) {

          this.staticLeftRow.map((element, index) => {
            //  
            let data = this.leftRow.filter(data => (data.heading === element.heading));
            if (data.length === 0) {
              if (element.heading === 'User Shipment Per Month' && element.show === true) {
                setTimeout(() => {
                  this.getshipmentPerMonth('rightGraph' + event.currentIndex, 'change', 'two', event.currentIndex, this.userShipmentPerMonth);
                }, 1)
              }

              else if (element.heading === 'Courier / LTL executed Per Month' && element.show === true) {
                setTimeout(() => {
                  this.getshipmentPerMonthLTL('rightGraph' + event.currentIndex, 'change', 'two', event.currentIndex, this.courierLTLPerMonth);
                }, 1)
              }

              else if (element.heading === 'Quotes Per Month' && element.show === true) {
                setTimeout(() => {
                  this.getQuotationPerMonth('rightGraph' + event.currentIndex, 'change', 'two', event.currentIndex, this.quotation);
                }, 200)
              } else if (element.heading === 'Regions Of Shipping' && element.show === true) {
                setTimeout(() => {
                  this.getRegionOfShipping('rightGraph' + event.currentIndex, 'change', 'two', event.currentIndex, this.regionOfShipping)
                }, 200)
              } else if (element.heading === 'All Shipments' && element.show === true) {
                setTimeout(() => {
                  this.getCountryData('rightGraph' + event.currentIndex, 'change', 'two', event.currentIndex, this.allShipment)
                }, 200)

              } else if (element.heading === 'Top 5 Carriers For User' && element.show === true) {
                setTimeout(() => {
                  this.getTopFiveCarrierData('rightGraph' + event.currentIndex, 'change', 'two', event.currentIndex, this.topFiveCarrier)
                }, 200)

              }

              else if (element.heading === 'Shipment Deliver by Region' && element.show === true) {
                setTimeout(() => {
                  this.getCAShipment('rightGraph' + event.currentIndex, 'change', 'two', event.currentIndex, this.topFiveCarrier)
                }, 200)

              }

              else if (element.heading === 'USA Region Shipments' && element.show === true) {
                setTimeout(() => {
                  this.getUSAShipment('rightGraph' + event.currentIndex, 'change', 'two', event.currentIndex, this.topFiveCarrier)
                }, 200)

              }

              else if (element.heading === 'User Accounts' && element.show === true) {
                setTimeout(() => {
                  this.getUserAccountData('rightGraph' + event.currentIndex, 'change', 'two', event.currentIndex, this.userAccount)
                }, 200)
              }
              else if (element.heading === 'Quotes By  Device' && element.show === true) {
                setTimeout(() => {
                  this.getQuoteByDevices('rightGraph' + event.currentIndex, 'change', 'two', event.currentIndex, this.quoteByDevices)
                }, 200)
              }
              else if (element.heading === 'User Activity' && element.show === true) {
                setTimeout(() => {
                  this.getUserActivityData('rightGraph' + event.currentIndex, 'change', 'two', event.currentIndex)
                }, 200)
              }
            }
          })
        } else if (event.previousContainer.id === id2) {
          this.staticRightRow.map((element, index) => {

            let data = this.rightRow.filter(data => data.heading === element.heading);
            if (data.length === 0) {
              if (element.heading === 'User Shipment Per Month' && element.show === true) {
                setTimeout(() => {

                  this.getshipmentPerMonth('leftGraph' + event.currentIndex, 'change', 'one', event.currentIndex, this.userShipmentPerMonth)
                }, 1)
              }

              else if (element.heading === 'Courier / LTL executed Per Month' && element.show === true) {
                setTimeout(() => {

                  this.getshipmentPerMonthLTL('leftGraph' + event.currentIndex, 'change', 'one', event.currentIndex, this.courierLTLPerMonth)
                }, 1)
              }
              else if (element.heading === 'Quotes Per Month' && element.show === true) {
                setTimeout(() => {
                  this.getQuotationPerMonth('leftGraph' + event.currentIndex, 'change', 'one', event.currentIndex, this.quotation);
                }, 200)
              } else if (element.heading === 'Regions Of Shipping' && element.show === true) {
                setTimeout(() => {

                  this.getRegionOfShipping('leftGraph' + event.currentIndex, 'change', 'one', event.currentIndex, this.regionOfShipping)
                }, 200)
              } else if (element.heading === 'All Shipments' && element.show === true) {
                setTimeout(() => {
                  this.getCountryData('leftGraph' + event.currentIndex, 'change', 'one', event.currentIndex, this.allShipment)
                }, 200)
              }
              else if (element.heading === 'Top 5 Carriers For User' && element.show === true) {
                setTimeout(() => {
                  this.getTopFiveCarrierData('leftGraph' + event.currentIndex, 'change', 'one', event.currentIndex, this.topFiveCarrier)
                }, 200)
              }

              else if (element.heading === 'Shipment Deliver by Region' && element.show === true) {
                setTimeout(() => {
                  this.getCAShipment('leftGraph' + event.currentIndex, 'change', 'one', event.currentIndex, this.topFiveCarrier)
                }, 200)
              }

              else if (element.heading === 'USA Region Shipments' && element.show === true) {
                setTimeout(() => {
                  this.getUSAShipment('leftGraph' + event.currentIndex, 'change', 'one', event.currentIndex, this.topFiveCarrier)
                }, 200)
              }

              else if (element.heading === 'User Accounts') {
                setTimeout(() => {
                  this.getUserAccountData('leftGraph' + event.currentIndex, 'change', 'one', event.currentIndex, this.userAccount)
                }, 200)
              }
              else if (element.heading === 'Quotes By  Device' && element.show === true) {
                setTimeout(() => {
                  this.getQuoteByDevices('leftGraph' + event.currentIndex, 'change', 'two', event.currentIndex, this.quoteByDevices)
                }, 200)
              }
              else if (element.heading === 'User Activity' && element.show === true) {
                setTimeout(() => {
                  this.getUserActivityData('leftGraph' + event.currentIndex, 'change', 'two', event.currentIndex)
                }, 200)
              }
            }
            // if(this.staticRightRow.length===index+1){
            //   setTimeout(()=>{
            //     this.staticLeftRow=this.leftRow
            //     this.staticRightRow=this.rightRow
            //   },3000)
            // }
          })


        }
      }
    }
  }
  dropGroup(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.groups, event.previousIndex, event.currentIndex);


  }
  formatNumber(num) {
    num = num.toString().replace(/[^0-9.]/g, '');
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1E3, s: "K" },
      { v: 1E6, s: "M" },
      { v: 1E9, s: "B" },
      { v: 1E12, s: "T" },
      { v: 1E15, s: "P" },
      { v: 1E18, s: "E" }
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (num / si[index].v).toFixed(3).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
  }
  clickOnView(mode) {
    let filterValue = this.keyIndicatorFilterForm.get('filterName').value;
    let month = 0;
    if (filterValue === 'LAST_3_MONTHS') {
      month = 3
    } else if (filterValue === 'LAST_6_MONTHS') {
      month = 6
    } else if (filterValue === 'LAST_9_MONTHS') {
      month = 9
    } else if (filterValue === 'LAST_YEAR') {
      month = 12
    }
    let date = this.getFromDateAndToDate(month)
    if (mode === 'Invoice Created') {
      this.router.navigateByUrl(`/shipcarte/allinvoices/filtered/${date.fromDate}/${date.toDate}`)
    } else if (mode === 'New Customer Added') {
      this.router.navigateByUrl(`/shipcarte/customer/list/filter/${date.fromDate}/${date.toDate}`)
    } else if (mode === 'Abandon Carts') {
      this.listDataService.removeAllDataOfQuoteHistory()
      // this.router.navigateByUrl(`shipcarte/quote/quote-list/${date.fromDate}/${date.toDate}/DRAFT`)
      this.router.navigateByUrl(`shipcarte/quoteHistory/allQuotes/view/draft/${date.fromDate}/${date.toDate}`)
    } else if (mode === 'Quotes Generated') {
      this.listDataService.removeAllDataOfQuoteHistory()
      // this.router.navigateByUrl(`shipcarte/quote/quote-list/${date.fromDate}/${date.toDate}`)
      this.router.navigateByUrl(`shipcarte/quoteHistory/allQuotes/view/${date.fromDate}/${date.toDate}`)
    } else if (mode === 'Shipments') {
      this.listDataService.removeAllOrderListData()
      this.router.navigateByUrl(`shipcarte/orders/filter/${date.fromDate}/${date.toDate}/isDashBoardView`);
    } else if (mode === 'Sales (USD)') {
      this.listDataService.removeAllOrderListData()
      this.router.navigateByUrl(`shipcarte/orders/filter/${date.fromDate}/${date.toDate}/isDashBoardView/USD`);

    } else if (mode === 'Sales (CAD)') {
      this.listDataService.removeAllOrderListData()
      this.router.navigateByUrl(`shipcarte/orders/filter/${date.fromDate}/${date.toDate}/isDashBoardView/CAD`);

    }
  }
  getPreviousDate(time) {
    let date = new Date();
    let day = date.getDate()
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let timePeriod = 0;
    if (time === 'LAST_6_MONTHS') {
      timePeriod = 182
    } else if (time === 'LAST_3_MONTHS') {
      timePeriod = 90
    } else if (time === 'LAST_MONTH') {
      timePeriod = 30
    } else if (time === 'LAST_2_WEEK') {
      timePeriod = 14;
    } else if (time === 'LAST_WEEK') {
      timePeriod = 7
    }
    var yesterday = date.setDate(date.getDate() - timePeriod);

    var previousDate = new Date(yesterday);

    let previousDay = previousDate.getDate();
    let previousMonth = previousDate.getMonth() + 1;
    let previousYear = previousDate.getFullYear();
    return { 'fromDate': previousYear + '-' + previousMonth + '-' + previousDay, 'toDate': year + '-' + month + '-' + day }
  }
  getFromDateAndToDate(month: number) {
    function subtractMonths(date: Date, months: number): Date {
      const dateCopy = new Date(date);
      dateCopy.setMonth(dateCopy.getMonth() - months);
      return dateCopy;
    }

    const date = new Date();

    // Get the last day of the current month
    let currentMonth = date.getMonth() + 1;
    let currentYear = date.getFullYear();
    let lastDayOfCurrentMonth = new Date(currentYear, currentMonth, 0).getDate();

    // Format the last day of the current month
    let currentDate = `${currentYear}-${String(currentMonth).padStart(2, '0')}-${lastDayOfCurrentMonth}`;

    // Calculate the date 6 months ago
    let newDate = subtractMonths(date, month);

    // Move the new date to the first day of the next month
    newDate.setMonth(newDate.getMonth() + 1);
    newDate.setDate(1);

    // Format the first day of the new date's month
    let previousDate = `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, '0')}-01`;

    return { 'toDate': currentDate, 'fromDate': previousDate };
  }


  setMonthInQuotePerMonthGraph(month) {

    let str = '';
    if (month === '1') {
      str = 'Jan'
    } else if (month === '2') {
      str = 'Feb'
    } else if (month === '3') {
      str = 'Mar'
    } else if (month === '4') {
      str = 'Apr'
    } else if (month === '5') {
      str = 'May'
    } else if (month === '6') {
      str = 'June'
    } else if (month === '7') {
      str = 'July'
    } else if (month === '8') {
      str = 'Aug'
    } else if (month === '9') {
      str = 'Sept'
    } else if (month === '10') {
      str = 'Oct'
    } else if (month === '11') {
      str = 'Nov'
    } else if (month === '12') {
      str = 'Dec'
    }
    return str;
  }
  printReport(value) {
    let filterValue = this.keyIndicatorFilterForm.get('filterName').value;
    let month = 0;
    if (filterValue === 'LAST_3_MONTHS') {
      month = 3
    } else if (filterValue === 'LAST_6_MONTHS') {
      month = 6
    } else if (filterValue === 'LAST_9_MONTHS') {
      month = 9
    } else if (filterValue === 'LAST_YEAR') {
      month = 12
    }
    let time = this.getFromDateAndToDate(month)
    if (value === 'Abandon Carts') {
      this.generateReportForAbandanCart(time);
    } else if (value === 'Claims') {
      this.generateReportForClaims(time);
    } else if (value === 'Invoice Created') {
      this.generateReportForInvoiceCreated(time);
    } else if (value === 'Sales (CAD)') {
      this.generateReportForSalesCAD(time);
    } else if (value === 'Quotes Generated') {
      this.generateReportForQuotesGenerated(time);
    } else if (value === 'Shipments') {
      this.generateReportForShipments(time);
    } else if (value === 'Sales (USD)') {
      this.generateReportForSalesUSD(time);
    } else if (value === 'New Customer Added') {
      this.generateReportForNewCustomerAdded(time);
    }

  }
  generateReportForAbandanCart(time) {
    this.openModel();
    let url = ServerURLS.getReportOfAbandantCart.action + '?startDate=' + time.fromDate + '&endDate=' + time.toDate;
    let promise = this.scHttpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.displayData = false
      this._loaderService.stringSubject.next(false);
      let data = []
      if (res['response'].length > 0) {
        res['response'].forEach((element, index) => {
          data.push({
            'Quote Code': element.quoteCode ? element.quoteCode : "",
            // 'Order Code':element.orderCode?element.orderCode:'',
            // "PO Number":element.poNumber?element.poNumber:'',
            "Pickup Date": element.pickupDate ? element.pickupDate : '',
            'Consignee': element.consigneeAddress ? element.consigneeAddress : '',
            'Shipper': element.shipperAddress ? element.shipperAddress : '',
            // 'Total Amount':element.totalAmount?element.totalAmount:'',
            'Status': element.quote_status ? element.quote_status : ''
          })
          if (index + 1 === res['response'].length) {
            this.closeModel()
            this.saveAsExcelFile('Abandan_Carts', data)
          }
        });

      } else {
        this.closeModel();
        this.toastr.error('No Data Available')
      }
    })
      .catch((err: any) => {
        this.closeModel();
        this.toastr.error("Something Went Wrong!")
      })
  }
  generateReportForClaims(time) {

  }
  generateReportForInvoiceCreated(time) {
    this.openModel()
    let url = ServerURLS.getReportOfInvoiceCreated.action + '?startDate=' + time.fromDate + '&endDate=' + time.toDate;
    let promise = this.scHttpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.displayData = false
      this._loaderService.stringSubject.next(false);
      let data = [];
      if (res['response'].length > 0) {
        res['response'].forEach((element, index) => {
          data.push({
            "Order Id": element.orderId ? element.orderId : '',
            "Quote Id": element.quoteId ? element.quoteId : "",
            "Invoice Number": element.invoiceNumber ? element.invoiceNumber : "",
            'Currency': element.currency ? element.currency : '',
            'Due Date': element.dueDate ? element.dueDate : "",
            'Invoice Date': element.invoiceDate ? element.invoiceDate : "",
            "Order Quantity": element.orderQuantity ? element.orderQuantity : "",
            'Remaninig Balance': element.remainingBalance ? element.remainingBalance : "",
            "Invoice Total": element.invoiceTotal ? element.invoiceTotal : "",
            'Invoice Type': element.type ? element.type : '',
            'Status': element.status ? element.status : ""
          })
          if (index + 1 === res['response'].length) {
            this.closeModel();
            this.saveAsExcelFile('Invoice_Created', data)
          }
        })
      }
    })
      .catch((err: any) => {
        this.closeModel();
        this.toastr.error("Something Went Wrong!")
      })
  }
  generateReportForSalesCAD(time) {
    this.openModel()
    let url = ServerURLS.getReportOfSalesCAD.action + '?startDate=' + time.fromDate + '&endDate=' + time.toDate;
    let promise = this.scHttpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.displayData = false
      this._loaderService.stringSubject.next(false);
      let data = [];
      if (res['response'].length > 0) {
        res['response'].forEach((element, index) => {
          data.push({
            "Order Number": element.orderCode ? element.orderCode : "",
            'Carrier Name': element.carrierName ? element.carrierName : "",
            'Shipper': element.shipper ? element.shipper : "",
            'Consignee': element.consignee ? element.consignee : "",
            "Destination": element.destination ? element.destination : '',
            'Origin': element.origin ? element.origin : "",
            'Currency': element.currency ? element.currency : "",
            'HST/GST': element.hstGst ? element.hstGst : '',
            'Pickup Date': element.pickupDate ? element.pickupDate : "",
            'PO Number': element.poNumber ? element.poNumber : "",
            'Service': element.service ? element.service : "",
            'Source': element.source ? element.source : '',
            'Total Cost': element.totalCost ? element.totalCost : "",
            'Status': element.status ? element.status : ""
          })
          if (index + 1 === res['response'].length) {
            this.closeModel();
            this.saveAsExcelFile('Sales_CAD', data)
          }
        })
      } else {
        this.closeModel();
        this.toastr.error("No Data Available");
      }
    })
      .catch((err: any) => {
        this.closeModel();
        this.toastr.error("Something Went Wrong!")
      })
  }
  generateReportForQuotesGenerated(time) {
    this.openModel();
    let url = ServerURLS.getReportOfQuoteGenerated.action + '?startDate=' + time.fromDate + '&endDate=' + time.toDate;
    let promise = this.scHttpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.displayData = false
      this._loaderService.stringSubject.next(false);
      let data = [];
      if (res['response'].length > 0) {
        res['response'].forEach((element, index) => {
          let obj = {
            'Quote Number': element.quoteNumber ? element.quoteNumber : "",
            // 'Carrier Name':element.carrierName?element.carrierName:'',
            'Consignee': element.consignee ? element.consignee : "",
            'Destination': element.destination ? element.destination : '',
            'Origin': element.origin ? element.origin : "",
            'Shipper': element.shipper ? element.shipper : "",
            'Pickup Date': element.pickUpDate ? element.pickUpDate : "",
            "Quote Value": element.quoteValue ? element.quoteValue : "",
            'Quote Date': element.quotedDate ? element.quotedDate : "",
            'Service': element.service ? element.service : "",
            'Status': element.status ? element.status : "",
            'Length': element.item[0].length ? element.item[0].length : "",
            'Breadth': element.item[0].breadth ? element.item[0].breadth : '',
            'Height': element.item[0].height ? element.item[0].height : '',
            'Weight': element.item[0].weight ? element.item[0].weight : '',
            'Piece Count': element.item[0].pieceCount ? element.item[0].pieceCount : '',
            'Item Value': element.item[0].itemValue ? element.item[0].itemValue : ''
          }
          data.push(obj)
          if (element.item.length > 1) {
            element.item.forEach((element, index) => {
              if (index >= 1) {
                let obj = {
                  'Quote Number': "",
                  // 'Carrier Name': '',
                  'Consignee': "",
                  'Destination': '',
                  'Origin': "",
                  'Shipper': "",
                  'Pickup Date': "",
                  "Quote Value": "",
                  'Quote Date': "",
                  'Service': "",
                  'Status': "",
                  'Length': element.length ? element.length : "",
                  'Breadth': element.breadth ? element.breadth : '',
                  'Height': element.height ? element.height : '',
                  'Weight': element.weight ? element.weight : '',
                  'Piece Count': element.pieceCount ? element.pieceCount : '',
                  'Item Value': element.itemValue ? element.itemValue : ''
                }
                data.push(obj)
              }
            })

          }
          if (index + 1 === res['response'].length) {
            this.closeModel();
            this.saveAsExcelFile('Quote_Generated', data)
          }
        })
      } else {
        this.closeModel();
        this.toastr.error("No Data Available");
      }
    })
      .catch((err: any) => {
        this.closeModel();
        this.toastr.error("Something Went Wrong!")
      })
  }
  generateReportForShipments(time) {
    this.openModel();
    let url = ServerURLS.getReportOfShipments.action + "?startDate=" + time.fromDate + '&endDate=' + time.toDate;
    let promise = this.scHttpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      let data = [];
      if (res['response'].length > 0) {
        res['response'].forEach((element, index) => {
          data.push({
            "Order Number": element.orderCode ? element.orderCode : "",
            'Carrier Name': element.carrierName ? element.carrierName : "",
            'Shipper': element.shipper ? element.shipper : "",
            'Consignee': element.consignee ? element.consignee : "",
            "Destination": element.destination ? element.destination : '',
            'Origin': element.origin ? element.origin : "",
            'Currency': element.currency ? element.currency : "",
            'HST/GST': element.hstGst ? element.hstGst : '',
            'Pickup Date': element.pickupDate ? element.pickupDate : "",
            'PO Number': element.poNumber ? element.poNumber : "",
            'Service': element.service ? element.service : "",
            'Source': element.source ? element.source : '',
            'Total Cost': element.totalCost ? element.totalCost : "",
            'Status': element.status ? element.status : ""
          })
          if (index + 1 === res['response'].length) {
            this.closeModel();
            this.saveAsExcelFile('Shipments', data)
          }
        })
      } else {
        this.closeModel();
        this.toastr.error("No Data Available")
      }
    })
      .catch((err: any) => {
        this.closeModel();
        this.toastr.error("Something Went Wrong!")
      })
  }
  generateReportForSalesUSD(time) {
    this.openModel();
    let url = ServerURLS.getReportOfSalesUSD.action + '?startDate=' + time.fromDate + '&endDate=' + time.toDate;
    let promise = this.scHttpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      let data = [];
      if (res['response'].length > 0) {
        res['response'].forEach((element, index) => {
          data.push({
            "Order Number": element.orderCode ? element.orderCode : "",
            'Carrier Name': element.carrierName ? element.carrierName : "",
            'Shipper': element.shipper ? element.shipper : "",
            'Consignee': element.consignee ? element.consignee : "",
            "Destination": element.destination ? element.destination : '',
            'Origin': element.origin ? element.origin : "",
            'Currency': element.currency ? element.currency : "",
            'HST/GST': element.hstGst ? element.hstGst : '',
            'Pickup Date': element.pickupDate ? element.pickupDate : "",
            'PO Number': element.poNumber ? element.poNumber : "",
            'Service': element.service ? element.service : "",
            'Source': element.source ? element.source : '',
            'Total Cost': element.totalCost ? element.totalCost : "",
            'Status': element.status ? element.status : ""
          })
          if (index + 1 === res['response'].length) {
            this.closeModel();
            this.saveAsExcelFile("Sales_USD", data)
          }
        })
      } else {
        this.closeModel();
        this.toastr.error("No Data Available");
      }
    })
      .catch((err: any) => {
        this.closeModel();
        this.toastr.error("Something Went Wrong!")
      })
  }
  generateReportForNewCustomerAdded(time) {
    this.openModel();
    let url = ServerURLS.getReportOfNewCustomerAdded.action + '?startDate=' + time.fromDate + '&endDate=' + time.toDate;
    let promise = this.scHttpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      let data = [];
      if (res['response'].length > 0) {
        res['response'].forEach((element, index) => {
          data.push({
            "Company Name": element.company_name ? element.company_name : "",
            'Email': element.email ? element.email : "",
            'Phone': element.phone_numbr ? element.phone_numbr : "",
            "Full Address": element.full_address ? element.full_address : "",
            'Contact Name': element.contact_name ? element.contact_name : ""
          })
          if (index + 1 === res['response'].length) {
            this.closeModel();
            this.saveAsExcelFile('New_Customer_Added', data);
          }
        })
      } else {
        this.closeModel();
        this.toastr.error("No Data Available")
      }
    })
      .catch((err: any) => {
        this.closeModel();
        this.toastr.error("Something Went Wrong!")
      })
  }
  saveAsExcelFile(fileName: String, Data: any) {
    const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(Data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': workSheet }, SheetNames: ["data"] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    fileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  openModel() {
    // this.modelService.open(this.loadConten, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal', backdrop: 'static' })
  }
  closeModel() {
    this.modelService.dismissAll();
  }
  // ngOnDestroy() {
  //    
  // }
}