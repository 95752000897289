import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { LoadingService } from 'src/app/services/loader.service';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-insurance-processing-edit',
  templateUrl: './insurance-processing-edit.component.html',
  styleUrls: ['./insurance-processing-edit.component.scss']
})
export class InsuranceProcessingEditComponent implements OnInit {
  countryData = {
    success: [
      {
        countryPhCode: "+1",
        countryName: "Canada",
        countryCode: "CA",
        provinceState: [
          {
            code: "AB",
            name: "Alberta"
          },
          {
            code: "BC",
            name: "British Columbia"
          },
          {
            code: "MB",
            name: "Manitoba"
          },
          {
            code: "NB",
            name: "New Brunswick"
          },
          {
            code: "NL",
            name: "Newfoundland and Labrador"
          },
          {
            code: "NT",
            name: "Northwest Territories"
          },
          {
            code: "NS",
            name: "Nova Scotia"
          },
          {
            code: "NU",
            name: "Nunavut"
          },
          {
            code: "ON",
            name: "Ontario"
          },
          {
            code: "PE",
            name: "Prince Edward Island"
          },
          {
            code: "QC",
            name: "Quebec"
          },
          {
            code: "SK",
            name: "Saskatchewan"
          },
          {
            code: "YT",
            name: "Yukon"
          }
        ]
      },
      {
        countryPhCode: "+1",
        countryName: "United States",
        countryCode: "US",
        provinceState: [
          {
            code: "AL",
            name: "Alabama"
          },
          {
            code: "AK",
            name: "Alaska"
          },
          {
            code: "AS",
            name: "American Samoa"
          },
          {
            code: "AZ",
            name: "Arizona"
          },
          {
            code: "AR",
            name: "Arkansas"
          },
          {
            code: "CA",
            name: "California"
          },
          {
            code: "CO",
            name: "Colorado"
          },
          {
            code: "CT",
            name: "Connecticut"
          },
          {
            code: "DE",
            name: "Delaware"
          },
          {
            code: "DC",
            name: "District of Columbia"
          },
          {
            code: "FL",
            name: "Florida"
          },
          {
            code: "GA",
            name: "Georgia"
          },
          {
            code: "GU",
            name: "Guam"
          },
          {
            code: "HI",
            name: "Hawaii"
          },
          {
            code: "ID",
            name: "Idaho"
          },
          {
            code: "IL",
            name: "Illinois"
          },
          {
            code: "IN",
            name: "Indiana"
          },
          {
            code: "IA",
            name: "Iowa"
          },
          {
            code: "KS",
            name: "Kansas"
          },
          {
            code: "KY",
            name: "Kentucky"
          },
          {
            code: "LA",
            name: "Louisiana"
          },
          {
            code: "ME",
            name: "Maine"
          },
          {
            code: "MD",
            name: "Maryland"
          },
          {
            code: "MA",
            name: "Massachusetts"
          },
          {
            code: "MI",
            name: "Michigan"
          },
          {
            code: "MN",
            name: "Minnesota"
          },
          {
            code: "MS",
            name: "Mississippi"
          },
          {
            code: "MO",
            name: "Missouri"
          },
          {
            code: "MT",
            name: "Montana"
          },
          {
            code: "NE",
            name: "Nebraska"
          },
          {
            code: "NV",
            name: "Nevada"
          },
          {
            code: "NH",
            name: "New Hampshire"
          },
          {
            code: "NJ",
            name: "New Jersey"
          },
          {
            code: "NM",
            name: "New Mexico"
          },
          {
            code: "NY",
            name: "New York"
          },
          {
            code: "NC",
            name: "North Carolina"
          },
          {
            code: "ND",
            name: "North Dakota"
          },
          {
            code: "OH",
            name: "Ohio"
          },
          {
            code: "OK",
            name: "Oklahoma"
          },
          {
            code: "OR",
            name: "Oregon"
          },
          {
            code: "PA",
            name: "Pennsylvania"
          },
          {
            code: "PR",
            name: "Puerto Rico"
          },
          {
            code: "RI",
            name: "Rhode Island"
          },
          {
            code: "SC",
            name: "South Carolina"
          },
          {
            code: "SD",
            name: "South Dakota"
          },
          {
            code: "TN",
            name: "Tennessee"
          },
          {
            code: "TX",
            name: "Texas"
          },
          {
            code: "VI",
            name: "U.S. Virgin Islands"
          },
          {
            code: "UT",
            name: "Utah"
          },
          {
            code: "VT",
            name: "Vermont"
          },
          {
            code: "VA",
            name: "Virginia"
          },
          {
            code: "WA",
            name: "Washington"
          },
          {
            code: "WV",
            name: "West Virginia"
          },
          {
            code: "WI",
            name: "Wisconsin"
          },
          {
            code: "WY",
            name: "Wyoming"
          }
        ]
      },
    ]
  };
  insuracneForm: FormGroup;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private httpService: ScHttpService, private fb: FormBuilder, private toastService: ToastrService, private _loaderService: LoadingService) {
    this.insuracneForm = this.fb.group({
      carrierSelect: [{ value: '', disabled: true }],
      arrangedWith: [{ value: '', disabled: true }],
      trailerNumber: [{ value: '', disabled: true }],
      equipment: [{ value: '', disabled: true }],
      driverName: [{ value: '', disabled: true }],
      contractRate: [{ value: '', disabled: true }],
      contractRateFlag: [{ value: '', disabled: true }],
      truckNumber: [{ value: '', disabled: true }],
      carrierRef: [{ value: '', disabled: true }],
      instruction: [{ value: '', disabled: true }],
      paymentDetails: this.fb.array([this.addLine()]),
      baseChanges: [{ value: '', disabled: true }],
      tax: [{ value: '', disabled: true }, [Validators.pattern(/^[0-9]+$/)]],
      subtotal: [{ value: '', disabled: true }],
      balancedue: [{ value: '', disabled: true }]
    })
  }
  quoteId = '';
  logo = '';
  insuraceDetails;
  carrierSelected = []
  selectedCityName = ''
  incuranceNumber = '';

  isInOrder: Boolean = true;
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params)
      if (!params.quoteId) {
        this.router.navigateByUrl('shipcarte/allinvoices/insuranceprocessing')
      } else {
        this.quoteId = params.quoteId

        this.getDetails();
      }

    });
    let url = window.location.href.split('/');
    if (url[url.length - 1].includes('insurance-edit')) {
      this.isInOrder = true;
    } else {
      this.isInOrder = false
    }
  }
  totalCost = 0;
  revenue;
  taxPercentage = 0;
  getDetails() {
    this._loaderService.stringSubject.next(true);
    let url = ServerURLS.getInsuranceDetails.action + '?quoteId=' + this.quoteId;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((result: any) => {
      this._loaderService.stringSubject.next(false);
      console.log(result)
      this.insuraceDetails = result;
      this.incuranceNumber = result.insuranceNumber ? result.insuranceNumber : '--';
      this.logo = result.insuranceLogo ? result.insuranceLogo : '';
      this.carrierSelected = [
        { id: 1, name: 'Marsh', avatar: this.logo ? this.logo : '/assets/img/avatar.png' }
      ]

      this.selectedCityName = 'Marsh'
      this.insuracneForm.get('paymentDetails')['controls'][0].patchValue({
        quantity: 1,
        price: result.spCost,
        chargeDescription: 'Insurance',
        totalCharge: (1 * result.spCost).toFixed(2),
        probill: result.probillNumber
      })
      this.totalCost = result.spCost
      this.insuracneForm.patchValue({
        baseChanges: this.totalCost.toFixed(2),
        subtotal: this.totalCost,
        tax: result.taxAmount.toFixed(2)
      })
      this.taxPercentage = result.taxPercent;
      this.revenue = Number(result.shipcarteCost).toFixed(2);
      this.addTotalCharge();
    }).catch((error) => {
      this._loaderService.stringSubject.next(false);

      this.toastService.error("Something Went Wrong!")
    })
  }
  profit = 0;
  profitPercentage = 0;
  addTotalCharge() {
    let sum = 0;

    for (let i = 0; i < this.insuracneForm.controls.paymentDetails['controls'].length; i++) {
      let value = this.insuracneForm.controls.paymentDetails['controls'][i].controls.totalCharge.value;
      if (parseFloat(value) > 0 && !isNaN(value)) {
        sum = sum + parseFloat(value);
      }
    }
    console.log('sdfksflksjflsdkjf', sum)
    this.profit = (this.revenue ? this.revenue : 0.00) - this.totalCost;
    if (this.totalCost != 0) {
      this.profitPercentage = (this.profit / this.totalCost) * 100;

    } else if (this.totalCost === 0 && this.revenue === 0) {
      this.profitPercentage = 0;
    } else if (this.totalCost === 0 && this.revenue !== 0) {
      this.profitPercentage = 0
    }
  }

  increaceQuantity(index) {
    let value = this.insuracneForm.controls.paymentDetails['controls'][index].controls.quantity.value;
    if (value === null || value === '') {
      value = 0;

    } else {
      value = parseInt(value) + 1;

    }


    this.insuracneForm.controls.paymentDetails['controls'][index].patchValue({
      quantity: value
    })
    let price = this.insuracneForm.get('paymentDetails')['controls'][index].controls.price.value
    let formatNum = Math.ceil(price * value * 100) / 100;

    this.insuracneForm.controls.paymentDetails['controls'][index].patchValue({
      totalCharge: formatNum.toFixed(2)
    })
    const taxAmount = (this.taxPercentage * formatNum) / 100;
    this.insuracneForm.patchValue({
      baseChanges: formatNum,
      tax: taxAmount.toFixed(1)
    })
    this.totalCost = formatNum
    this.addTotalCharge()
  }
  onQuantityChange(value, index) {
    console.log(value, index)
    const price = this.insuracneForm.controls.paymentDetails['controls'][index].controls.price.value;
    let formatNum = Math.ceil(price * value * 100) / 100;

    this.insuracneForm.controls.paymentDetails['controls'][index].patchValue({
      totalCharge: formatNum
    })
    const taxAmount = (this.taxPercentage * formatNum) / 100;
    this.insuracneForm.patchValue({
      baseChanges: formatNum,
      tax: taxAmount.toFixed(1)
    })
    this.totalCost = formatNum

    this.addTotalCharge()
  }
  decreaseQuantity(index) {
    let value = this.insuracneForm.controls.paymentDetails['controls'][index].controls.quantity.value;
    if (parseInt(value) > 0) {
      value = parseInt(value) - 1;
    }
    this.insuracneForm.controls.paymentDetails['controls'][index].patchValue({
      quantity: value
    })
    let price = this.insuracneForm.get('paymentDetails')['controls'][index].controls.price.value
    let formatNum = Math.ceil(price * value * 100) / 100;
    this.insuracneForm.get("paymentDetails")['controls'][index].patchValue({
      totalCharge: formatNum
    })

    const taxAmount = (this.taxPercentage * formatNum) / 100;
    this.insuracneForm.patchValue({
      baseChanges: formatNum,
      tax: taxAmount.toFixed(1)
    })
    this.totalCost = formatNum

    this.addTotalCharge()
  }
  addLine(): FormGroup {
    return this.fb.group({
      chargeIte: ['Insurance', [Validators.required]],
      probill: [{ value: '', disabled: true }],
      chargeDescription: [{ value: '', disabled: true }],
      quantity: ['', [Validators.required, Validators.min(1), Validators.max(99999), Validators.pattern(/^[0-9]+$/)]],
      price: ['', [Validators.required, Validators.min(0.1), Validators.max(99999), Validators.pattern(/^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/)]],
      // afs: [false],
      // typeOfafs: [''],
      // afsAmount: [''],
      totalCharge: [{ value: 0, disabled: true }],
      // newLine: true,
      // isAdjust: "true"
    })
  }
  updateDetails() {
    this._loaderService.stringSubject.next(true);

    let url = ServerURLS.updateInsurance.action + `?quoteId=${this.quoteId}&sp_cost=${this.totalCost}`;
    let promise = this.httpService.patchHttpService(url, '');
    promise.then((result) => {
      this._loaderService.stringSubject.next(false);
      this.toastService.success("Insurance Update Successfully.")
      if (this.isInOrder) {
        this.getDetails();
      } else {

        this.router.navigateByUrl('shipcarte/allinvoices/insuranceprocessing')
      }
    }).catch((error: any) => {
      this._loaderService.stringSubject.next(false);

      this.toastService.error("Something Went Wrong!")
    })
  }
  priceinput(value, index) {
    console.log(value)
    let quantity = this.insuracneForm.controls.paymentDetails['controls'][index].controls.quantity.value;
    let formatNum = Math.ceil(quantity * value * 100) / 100;
    this.insuracneForm.get('paymentDetails')['controls'][index].patchValue({
      totalCharge: formatNum
    })

    const taxAmount = (this.taxPercentage * formatNum) / 100;
    this.insuracneForm.patchValue({
      baseChanges: formatNum,
      tax: taxAmount.toFixed(1)
    })
    this.totalCost = formatNum
    this.addTotalCharge()
  }
  goBackToList() {
    this.router.navigateByUrl('shipcarte/allinvoices/insuranceprocessing')
  }

}
